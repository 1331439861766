import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {

  constructor(private http: HttpClient) { }

  creditHistory(params: any) {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcDashboard/CreditHistory', { params });
  }

  creditHealthInfo(params: any) {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcDashboard/CreditHealthInfo', { params });
  }

  CheckCreditHistoryCurrentMonth(params: any) {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcDashboard/CheckCreditHistoryCurrentMonth', { params });
  }

  UpdateCreditHistoryCurrentMonth(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcDashboard/UpdateCreditHistoryCurrentMonth', data);
  }

  creditReportDetail(params: any){
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcDashboard/CreditReportDetail', { params }); 
  } 

  getIfCreditHistoryExists(params: any) {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcDashboard/IsCreditHistoryExists', { params });
  }

  getCreditHistoryFromBureau(params: any) {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcDashboard/FetchFromBureau', { params });
  }

  getCreditHistoryFromDatabase(params: any) {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcDashboard/CreditHistoryFromDatabase', { params });
  }

  getCreditScorePercentChart(params: any){
    return this.http.get(environment.ccAgentWebApiUrl  + 'api/CcDashboard/GetCreditScorepercent', { params });
  }

  getSimpicityDetails(params: any){
    return this.http.get(environment.ccAgentWebApiUrl  + 'api/CcDashboard/GetSimplicityDetails', { params });
  }
}
