import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { WelcomeTextService } from '../../RxJs/welcome-text.service';
import { WelcomeTextStatusMapping } from '../../helpers/enums/welcome-text-status';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/helpers/ngbDatePickerFormat/dateformat';
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import * as $ from 'jquery';

@Component({
  selector: 'app-welcome-text',
  templateUrl: './welcome-text.component.html',
  styleUrls: ['./welcome-text.component.css'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class WelcomeTextComponent implements OnInit {
  @ViewChild('EditModel', { static: false }) EditModel: ElementRef;
  paramData;
  welcomeTextForm: FormGroup;
  welcomeLogFilterForm: FormGroup;
  editUpdateForm: FormGroup;
  successMsgAddLog = '';
  warningMsgAddLog = '';
  warningUpdateMsg = '';
  successUpdateMsg = '';
  activeWelcomeMessageList;
  welcomeTextStatus;
  countLogs;
  modalOptions: NgbModalOptions;
  editId;
  isActive;
  minDate = undefined;
  isAdmin = false;

  userTypesArray: Array<any> = [
    { name: 'Type 1 Users', value: 1, isActive: false },
    { name: 'Type 2 Users', value: 2, isActive: false },
    { name: 'Type 3 Users', value: 3, isActive: false }
  ];

  constructor(private formBuilder: FormBuilder,
    private welcomeTextService: WelcomeTextService,
    private modalService: NgbModal,
    private adalService: MsAdalAngular6Service
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };

    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
  }


  ngOnInit() {

    if (this.adalService.userInfo && this.adalService.userInfo.profile
      && this.adalService.userInfo.profile.roles && this.adalService.userInfo.profile.roles.indexOf('Admin') !== -1) {
      this.isAdmin = true;
    }

    //this.isAdmin = true;  //remove it while commit code just for testing i applied.

    this.welcomeTextReset();
    this.getWelcomeMsgDetails();
    this.welcomeTextStatus = WelcomeTextStatusMapping;

    this.welcomeLogFilterForm = this.formBuilder.group({
      activeDeactiveStatus: ['', Validators.required],

    });
    this.editUpdateForm = this.formBuilder.group({
      description: [''],
      isActive: [''],
      expiryDate: [''],
      userTypes: this.formBuilder.array([], [Validators.required])
    });
  }

  private welcomeTextReset() {
    this.welcomeTextForm = this.formBuilder.group({
      description: ['', [Validators.required]],
      isActive: false,
      expiryDate: null,
      userTypes: this.formBuilder.array([], [Validators.required])
    });
    this.warningMsgAddLog = '';
    this.successMsgAddLog = '';
  }

  get welcomeTextFormControls() { return this.welcomeTextForm.controls; }
  get welcomeLogFilterFormControls() { return this.welcomeLogFilterForm.controls; }


  openEditPopup(i) {
    console.log('id is' + i);
    this.editId = i;
    this.modalService.open(this.EditModel, { size: 'md' });
    this.getLogDetails();
  }

  editUpdateUserTypes: Array<any> = [
    { name: 'Type 1 Users', value: 1, isActive: false },
    { name: 'Type 2 Users', value: 2, isActive: false },
    { name: 'Type 3 Users', value: 3, isActive: false }
  ];

  getLogDetails() {
    this.editUpdateUserTypes.forEach(function (part, index) { part.isActive = false; }); // reset types

    const param = { id: this.editId };
    this.welcomeTextService.getWelcomeMessageById(param).subscribe((data: any) => {

      if (data != null) {
        var dateValue = null;
        if (data.expiryDate != '' && data.expiryDate != null) {
          var expiryDate = new Date(data.expiryDate);
          dateValue = { year: expiryDate.getFullYear(), month: (expiryDate.getMonth() + 1), day: expiryDate.getDate() };
        }

        console.log(data);

        this.editUpdateForm.patchValue({
          description: data.description,
          isActive: data.isActive,
          expiryDate: dateValue
        });

        var checkArray: FormArray = this.editUpdateForm.get('userTypes') as FormArray;

        for (let i = checkArray.controls.length - 1; i >= 0; i--) {
          checkArray.removeAt(i);
        } // reset old checkArray

        if (data.userTypes != null) {
          for (var val of data.userTypes) {
            checkArray.push(new FormControl(val));
            var item = this.editUpdateUserTypes.findIndex((obj => obj.value == val));
            this.editUpdateUserTypes[item].isActive = true;
          }
        }

      }
    }, (err: HttpErrorResponse) => {

    });
  }

  get editUpdateFormControls() { return this.editUpdateForm.controls; }

  saveWelcomeTextInfo() {
    this.warningMsgAddLog = '';
    this.successMsgAddLog = '';

    if (this.welcomeTextForm.invalid) {
      this.welcomeTextForm.markAllAsTouched();
      return;
    }

    var expiryDateValue = '';
    if (this.welcomeTextForm.value.expiryDate != '' && this.welcomeTextForm.value.expiryDate != null) {
      expiryDateValue = this.welcomeTextForm.value.expiryDate.year + '/' + this.welcomeTextForm.value.expiryDate.month + '/' + this.welcomeTextForm.value.expiryDate.day;
    }

    const param = {
      description: this.welcomeTextForm.value.description, isActive: this.welcomeTextForm.value.isActive
      , expiryDate: expiryDateValue, userTypes: this.welcomeTextForm.value.userTypes
    };

    console.log(param);

    this.welcomeTextService.insertWelcomeText(param).subscribe((data: any) => {
      this.welcomeTextReset();
      this.successMsgAddLog = 'Added Successfully.';
      this.getWelcomeMsgDetails();

      //below code is for reset checkboxes
      $('.cCheckbox').prop('checked', false);
      var checkArray: FormArray = this.welcomeTextForm.get('userTypes') as FormArray;
      for (let i = checkArray.controls.length - 1; i >= 0; i--) {
        checkArray.removeAt(i);
      }

    },
      (err: HttpErrorResponse) => {
        this.warningMsgAddLog = err.message;
      });

  }

  getWelcomeMsgDetails() {
    this.welcomeTextService.getLatestWelcomeMessage().subscribe((data: any) => {
      console.log(data);
      this.activeWelcomeMessageList = data.welcomeTextLog;
      this.countLogs = data.totalRecords;
    }, (err: HttpErrorResponse) => {

    });
  }

  getFilteredWelcomeLog() {
    this.warningUpdateMsg = '';
    this.successUpdateMsg = '';

    if (this.welcomeLogFilterForm.invalid) {
      this.welcomeLogFilterForm.markAllAsTouched();
      return;
    }

    console.log(this.welcomeLogFilterForm.value);
    const param = { welcomeTextStatus: this.welcomeLogFilterForm.value.activeDeactiveStatus };
    this.welcomeTextService.getFilteredMessage(param).subscribe((data: any) => {
      console.log(data);
      this.activeWelcomeMessageList = data.welcomeTextLog;
      this.countLogs = data.totalRecords;
    }, (err: HttpErrorResponse) => {

    });
  }

  updateWelcomeTextInfo() {
    this.warningUpdateMsg = '';
    this.successUpdateMsg = '';

    if (this.editUpdateForm.invalid) {
      this.editUpdateForm.markAllAsTouched();
      return;
    }

    var expiryDateValue = '';
    if (this.editUpdateForm.value.expiryDate != '' && this.editUpdateForm.value.expiryDate != null) {
      expiryDateValue = this.editUpdateForm.value.expiryDate.year + '/' + this.editUpdateForm.value.expiryDate.month + '/' + this.editUpdateForm.value.expiryDate.day;
    }

    const param = {
      id: this.editId, description: this.editUpdateForm.value.description, isActive: this.editUpdateForm.value.isActive, expiryDate: expiryDateValue
      , userTypes: this.editUpdateForm.value.userTypes
    };

    console.log(param);
    this.welcomeTextService.updatedWelcomeTextId(param).subscribe((data: any) => {
      this.successUpdateMsg = 'Updated Successfully.';
      this.modalService.dismissAll();
      this.getWelcomeMsgDetails();
    },
      (err: HttpErrorResponse) => {
        this.warningUpdateMsg = err.message;
      });
  }

  onCheckboxChange(e) {
    const checkArray: FormArray = this.welcomeTextForm.get('userTypes') as FormArray;
    this.setChecked(e, checkArray);
  }

  onCheckboxChangeEdit(e) {
    const checkArray: FormArray = this.editUpdateForm.get('userTypes') as FormArray;
    this.setChecked(e, checkArray);
  }

  private setChecked(e: any, checkArray: FormArray) {
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }


}
