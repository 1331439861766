import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn : 'root'
})

export class InsuranceService{

    env = environment;
    constructor(private http:HttpClient) {}

    getPolicyHolderDetails(data: any){
        return this.http.post(environment.ccAgentWebApiUrl + 'api/CCInsurance/GetPolicyHolderDetails', data);
    }   
    
    getInsurancePolicyDetails(data: any){
        return this.http.post(environment.ccAgentWebApiUrl + 'api/CCInsurance/GetInsurancePolicyDetails', data);
    }
}