export enum CommunicationLogTransferType {
    AbandonedChats = 'Abandoned Chats',
    CrCreditScore = 'CR - Credit Score',
    CrAccountInformation = 'CR - Account Information',
    CrEscalateToVericred = 'CR - Escalate to Vericred',
    CrAdverseInformation = 'CR - Adverse Information',
    CrDeclinedCredit = 'CR - Declined Credit',
    TransferSPL = 'Transfer - SPL',
    TransferDebtBusters = 'Transfer - DebtBusters',
    TransferSDLife = 'Transfer - SD - Life',
    TransferSDFuneral = 'Transfer - SD - Funeral',
    TransferSDIncomeProtection = 'Transfer - SD - Income Protection',
    TransferSDMedical = 'Transfer - SD - Medical',
    TransferSDShortTerm = 'Transfer - SD - Short Term',
    CallBack = 'Call Back',
    SanlamCustomerCareSecureServices = 'Sanlam Customer Care - Secure Service',
    SanlamCustomerCareReality = 'Sanlam Customer Care - Reality',
    SanlamCustomerCareCustomerServices = 'Sanlam Customer Care - Customer Services',
    AfterhoursChats = 'Afterhours Chats',
    Unanswered = 'Unanswered',
    Registration = 'Registration',
    NotInterested = 'Not Interested'
}

export const CommunicationLogTransferTypeMapping = [
    { type: CommunicationLogTransferType.AbandonedChats, value: 1 },
    { type: CommunicationLogTransferType.CrCreditScore, value: 2 },
    { type: CommunicationLogTransferType.CrAccountInformation, value: 3 },
    { type: CommunicationLogTransferType.CrEscalateToVericred, value: 4 },
    { type: CommunicationLogTransferType.CrAdverseInformation, value: 5 },
    { type: CommunicationLogTransferType.CrDeclinedCredit, value: 6 },
    { type: CommunicationLogTransferType.TransferSPL, value: 7 },
    { type: CommunicationLogTransferType.TransferDebtBusters, value: 8 },
    { type: CommunicationLogTransferType.TransferSDLife, value: 9 },
    { type: CommunicationLogTransferType.TransferSDFuneral, value: 10 },
    { type: CommunicationLogTransferType.TransferSDIncomeProtection, value: 11 },
    { type: CommunicationLogTransferType.TransferSDMedical, value: 12},
    { type: CommunicationLogTransferType.TransferSDShortTerm, value: 13 },
    { type: CommunicationLogTransferType.CallBack, value: 14 },
    { type: CommunicationLogTransferType.SanlamCustomerCareSecureServices, value: 15 },
    { type: CommunicationLogTransferType.SanlamCustomerCareReality, value: 16 },
    { type: CommunicationLogTransferType.SanlamCustomerCareCustomerServices, value: 17 },
    { type: CommunicationLogTransferType.AfterhoursChats, value: 18 },
    { type: CommunicationLogTransferType.Unanswered, value: 19 },
    { type: CommunicationLogTransferType.Registration, value: 20 },
    { type: CommunicationLogTransferType.NotInterested, value: 21 },

  ];
