import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
  })

  export class CommunicationReportService {

    constructor(private http: HttpClient) { }

getCommunicationReport(params: any) {
    return this.http.get(environment.ccOpsWebApiUrl + 'api/CommunicationLog/GetFilteredCommunicationLog', { params });
    }
}
