import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from 'src/app/RxJs/account.service';
import { DashboardService } from 'src/app/RxJs/dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  paramData;
  accountData = null;
  otherAccountsData = null; 
  simplicityAccData;
  userType;

  constructor(private accountService: AccountService,
              private dashboardService: DashboardService,
              private spinner: NgxSpinnerService,
    ) { }

  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
    this.userType =  this.paramData.dbcUserType;
    this.getAccountSummary();
    this.getOtherAccounts();
    if(this.userType === 1){
     this.getSimplicityDetails();
    }
  }

  ngOnInit() {
  }

  getAccountSummary() {
    const param = { idNumber: this.paramData.idNumber };
    this.accountService.accountSummary(param).subscribe((data: any) => {
      this.accountData = data;
    },
      (err: HttpErrorResponse) => {
      });
  }

  getOtherAccounts() {
    const param = { idNumber: this.paramData.idNumber };
    this.accountService.otherAccounts(param).subscribe((data: any) => {
      this.otherAccountsData = data; 
    },
      (err: HttpErrorResponse) => {
      });
  }



  getSimplicityDetails() {
    const param = { idNumber: this.paramData.idNumber };
    this.dashboardService.getSimpicityDetails(param).subscribe((data: any) => {
      if(data) {
        this.simplicityAccData = data.balanceDetails.obligations;
      }
    });
    
  }

}



