import { Pipe, PipeTransform } from '@angular/core';
import { CommunicationLogTransferType, CommunicationLogTransferTypeMapping } from '../enums/communication-log-transfer-type';

@Pipe({
  name: 'parseCommunicationLogTransferType'
})
export class ParseCommunicationLogTransferTypePipe implements PipeTransform {

  transform(value: any): any {
    const parsedData = CommunicationLogTransferTypeMapping.filter((data) => {
      return data.value === value;
    });
    if (parsedData.length > 0) {
      return parsedData[0].type;
    }
    return '';
  }
}
