import { Pipe, PipeTransform } from '@angular/core';
import { CommunicationLogType, CommunicationLogTypeMapping } from '../enums/communication-log-type';

@Pipe({
  name: 'parseCommunicationLogType'
})
export class ParseCommunicationLogTypePipe implements PipeTransform {

  transform(value: any): any {
    const parsedData = CommunicationLogTypeMapping.filter((data) => {
      return data.value === value;
    });
    if (parsedData.length > 0) {
      return parsedData[0].type;
    }
    return '';
  }
}
