import { Component, Input, OnInit } from '@angular/core';
import { DashboardComponent } from 'src/app/dashboard/dashboard.component';
import { DashboardService } from 'src/app/RxJs/dashboard.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {

  previousPayments;
  currentPayment;
  nextPayment;

  paramData;
  
  @Input()
  set passDataInComponent(paramData) { 
       
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
    this.getPaymentDetails();
  }
  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
  }

  
  getPaymentDetails(){

    const param = {  idNumber: this.paramData.idNumber };
    this.dashboardService.getSimpicityDetails(param).subscribe((data: any) => {
      if(data) {
        console.log("Data ", data);
        this.previousPayments = data.previousPaymentDetails;
        this.currentPayment = data.currentPaymentDetails;
        this.nextPayment = data.nextPaymentDetails;
      }
    
   });

  }
  







}
