export enum CommunicationLogType {
    Inbound = 'Inbound',
    Outbound = 'Outbound',
    Chat = 'Chat',
    Email = 'Email',
    Proactive = 'Proactive',
}

export const CommunicationLogTypeMapping = [
    { type: CommunicationLogType.Inbound, value: 1 },
    { type: CommunicationLogType.Outbound, value: 2 },
    { type: CommunicationLogType.Chat, value: 3 },
    { type: CommunicationLogType.Email, value: 4 },
    { type: CommunicationLogType.Proactive, value: 5},

  ];
