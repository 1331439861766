import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuditLogService } from 'src/app/RxJs/audit-log.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuditLogType } from '../helpers/enums/audit-log-type';
import { AuditLogUserActivationReasonTypeMapping } from '../helpers/enums/audit-log-user-activation-reason-type';
import { AuditLogUserDeactivationReasonTypeMapping } from '../helpers/enums/audit-log-user-deactivation-reason-type';
import { AuditLogUserUpdateReasonTypeMapping } from '../helpers/enums/audit-log-user-update-reason-type';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.css']
})
export class AuditLogComponent implements OnInit {
  auditLogForm; paramData;
  errorMessage; successMessage;
  auditLogReasonTypes;

  @Output()
  componentCallback = new EventEmitter<any>();

  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
  }

  constructor(
    private formBuilder: FormBuilder,
    private auditLogService: AuditLogService,
    ) {
    }

  ngOnInit() {
    this.errorMessage = ''; this.successMessage = '';
    this.initAuditForm();
    if (this.paramData) {
      this.auditLogForm.patchValue(this.paramData);

      if (this.paramData.auditLogTypeId === AuditLogType.UserActivation) {
        this.auditLogReasonTypes = AuditLogUserActivationReasonTypeMapping;
      } else if (this.paramData.auditLogTypeId === AuditLogType.UserDeactivation) {
        this.auditLogReasonTypes = AuditLogUserDeactivationReasonTypeMapping;
      } else if (this.paramData.auditLogTypeId === AuditLogType.UserProfileUpdate) {
        this.auditLogReasonTypes = AuditLogUserUpdateReasonTypeMapping;
      }
    }
  }

  initAuditForm() {
    this.auditLogForm = this.formBuilder.group({
      idNumber: [''],
      auditLogTypeId: [null, [Validators.required]],
      auditLogReasonId: [null, [Validators.required]],
      currentData: ['', [Validators.required]],
      updatedData: ['', [Validators.required]],
      remarks: ['']
    });
  }

  get auditLogFormControls() { return this.auditLogForm.controls; }

  saveLog() {
    this.errorMessage = ''; this.successMessage = '';
    if (this.auditLogForm.invalid) {
      this.auditLogForm.markAllAsTouched();
      return;
    }

    this.auditLogService.saveAuditLog(this.auditLogForm.value).subscribe((data: any) => {
      this.successMessage = 'Log Saved Successfully.';
      this.componentCallback.emit(data);
    }, (err: HttpErrorResponse) => {
      this.errorMessage = err.message;
    });

  }


}
