import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BureauBatchService {

  constructor(private http: HttpClient) { }

  uploadActiveUsersToSftp() {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/BureauBatch/UploadActiveUsersListToSftp', null);
  }

  processBureauBatchFileFromSftp(data) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/BureauBatch/DownloadAndProcessBureauFileFromSftp', data);
  }
}
