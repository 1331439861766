import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DataLookupService {

  constructor(private http: HttpClient) { }

  updateLookupForWebApi() {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/DataLookup/SaveDefaultLookupData');
  }

  updateLookupForOpsApi() {
    return this.http.get(environment.ccOpsWebApiUrl + 'api/DataLookup/SaveDefaultLookupData');
  }
}
