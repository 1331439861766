import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../RxJs/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InputValidationService } from '../helpers/validators/input-validation.service';
import { SearchUserModel } from '../models/searchusermodel';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PlatformLocation } from '@angular/common';
import { AuditLogType } from '../helpers/enums/audit-log-type';
import * as $ from 'jquery';
import { DataLookupService } from '../RxJs/data-lookup.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { BureauBatchService } from '../RxJs/bureau-batch.service';
import { CommunicationService } from '../RxJs/communication.service';
import { DashboardService } from 'src/app/RxJs/dashboard.service';
import { OtpService } from '../RxJs/otp.service';
import { UserActiveOrDeactiveModel } from '../models/userActiveOrDeactiveModel';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('userAuditLogModel', { static: false }) userAuditLogModel: ElementRef;
  @ViewChild('sftpPathModel', { static: false }) sftpPathModel: ElementRef;
  @ViewChild('creditHistoryUpdate', { static: false }) creditHistoryUpdate: ElementRef;
  @ViewChild('creditHistoryError', { static: false }) creditHistoryError: ElementRef;
  @ViewChild('transferToSplConfirmationModel', { static: false }) transferToSplConfirmationModel: ElementRef;
  @ViewChild('optModel', { static: false }) optModel: ElementRef;
  @ViewChild('fetchfromBureauModel', { static: false }) fetchfromBureauModel: ElementRef;
  
  userForm: FormGroup; sftpPathForm: FormGroup;
  changeDisable = true;
  successMsg = '';
  errorMessage = '';
  warningMsg = '';
  searchUserModel: SearchUserModel;
  userActiveOrDeactiveModel: UserActiveOrDeactiveModel;
  userData;
  martialStatus: any = ['Married - COP', 'Married - ANC', 'Single', 'Divorced', 'Widowed'];
  loginLogParam; registrationLogParam; communicationParam; auditLogParam; userAuditLogModelTitle; dashboardParam; accountsParam;
  productParam; communicationPreferenceLogParam;
  communicationLogFilterParam = false;
  isLogsCallFired = {
    loginLogsData: false,
    registrationLogsData: false,
    communicationPreferenceLogData: false,
  };
  isAccountsFired = false;
  isProductFired = false;
  modalOptions: NgbModalOptions;
  lookupMsg = '';
  lookupErrorMsg = '';
  // enableLookupButtons = false;
  // isAgent = false;
  splErrorMsg = '';
  splSuccessMsg = '';
  welcomeTextParam = false;
  isExternalLead = false;
  userId = '';
  isCreditDataAvailable = false;
  isCreditReportDetailFired = false;
  creditReportParam;
  oldPhoneNumber = '';
  newPhoneNumber = '';
  otpForm;
  sendOtpText = 'Send OTP'
  otpErrorMsg = '';
  otpSuccessMsg = '';
  btnOTPIsDisabled = false;
  isOtpSent = false;
  otpPopupHeaderText ='Fill OTP';

  fetchFromBureauMessage : string;
  fetchFromBureauButtonClicked = false;
  creditReportMonth;
  creditReportYear;
  ifCreditHistoryExitsInTheDatabase : boolean = false;
  ifCreditHistoryRequestByAgent : boolean = false;
  creditHistoryDoesNotExists : string =  "Please note- this will do a call to Bureau directly and will incur a cost. Are you sure you want to continue ?";
  creditHistoryExists :  string = "A report exists for this customer, for this month, already. Are you sure you want to do another Request to the Bureau ? \n  Please note- this will do a call to Bureau directly and will incur a cost. ";
  isCreditHistoryAvailble : boolean;
  // IsAdminOrAgent ='Admin';
  userRole = 'User';
  dbcUserType;
  isPaymentFired = false;
  paymentParams;
  accountPageName;
  insuranceParams;
  isInsuranceFired = false;
  campaignParam = false;
  leaderboardBannerParam = false;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private location: PlatformLocation,
    private modalService: NgbModal,
    private dataLookupService: DataLookupService,
    private adalService: MsAdalAngular6Service,
    private bureauBatchService: BureauBatchService,
    private communicationService: CommunicationService,
    private dashboardService: DashboardService,
    private otpService: OtpService 
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };
    location.onPopState(() => this.modalService.dismissAll());
  }

  ngOnInit() {
    this.resetSearchUserModel();
    this.userForm = this.formBuilder.group({
      azureAdObjectId: [''],
      firstName: [''],
      surName: [''],
      surname: [''],
      idNumber: [''],
      phoneNumber: [''],
      email: [''],
      isActive: [''],
      grossIncome: [''],
      financialGoals: [''],
      maritalStatus: [''],
      numberOfDependents: [null],
      //  financialGoals: ['', [Validators.required, InputValidationService.isValidText]],
      //   maritalStatus: ['', [Validators.required, InputValidationService.isValidText]],
      // numberOfDependents: [null, [Validators.required]],
      occupation: [''],
      employerName: [''],
      addressType: [''],
      addressLine1: [''],
      addressLine2: [''],
      suburb: [''],
      town: [''],
      description: [''],
      isVerified: [''],
      verifiedBy: [''],
      verifiedDate: [''],
      dbcUserType:[''],
      dbcUserTypeText:['']
    });

    this.bindNavBar();
    console.log(this.adalService.userInfo);
    // console.log(this.adalService.userInfo.profile.roles.length);
    if(this.adalService.userInfo && this.adalService.userInfo.profile 
      && this.adalService.userInfo.profile.roles && this.adalService.userInfo.profile.roles.length > 0) {
        this.userRole = this.adalService.userInfo.profile.roles[0];
    }

    // this.userRole = 'User'; 
    // console.log(this.userRole);

    this.sftpPathForm = this.formBuilder.group({
      filePath: ['', [Validators.required]]
    });

    this.otpForm = this.formBuilder.group({
      Otp: ['', [Validators.required, Validators.minLength(4)]] 
    });
  }

  bindNavBar() {
    const hamburger = $('.panel-toogl');
    const customProfile = $('.customer-profile');

    $(hamburger).click(function () {
      $(customProfile).toggleClass("push-menu-hidden");
    });
  }
  private resetSearchUserModel() {
    this.searchUserModel = {
      IdNumber: '',
      Email: '',
      PhoneNumber: ''
    };
  }

  get userFormControls() { return this.userForm.controls; }

  searchUser(search) {
    this.warningMsg = '';
    this.successMsg = '';
    this.errorMessage = '';
    this.userData = null;
    let webSourceDescription = "";
    this.resetSearchUserModel();
    if (InputValidationService.isValidIdNumber(search) == null) {
      this.searchUserModel.IdNumber = search.value.trim();
    } else if (InputValidationService.isValidEmail(search) == null) {
      this.searchUserModel.Email = search.value.toLowerCase().trim();
    } else if (InputValidationService.isValidMobile(search) == null) {
      this.searchUserModel.PhoneNumber = search.value.trim();
    } else {
      this.errorMessage = 'Please enter valid input.';
      this.userData = null;
      this.resetTabs();
      return;
    }
    this.userForm.reset();
    this.changeDisable = true;
    this.userService.searchUser(this.searchUserModel).subscribe((data: any) => {
      this.errorMessage = '';
      if (data != null && data.user != null) {
        // this.userData = data;
        this.userData = data.user; 
        this.userData.dbcUserTypeText = this.getDbcUserTypeText(this.userData.dbcUserType);
        webSourceDescription = data.userWebSource != null ? data.userWebSource.description : '';
        this.isExternalLead = data.isExternalLead;
        this.userId = data.user.id;
        this.oldPhoneNumber = data.user.phoneNumber;
        this.dbcUserType = data.user.dbcUserType;
        this.accountPageName = (this.dbcUserType === 1) ? "Account Balances" : "My Accounts";
        setTimeout(() => {
          this.userForm.patchValue(this.userData);
          this.userForm.controls['description'].setValue(webSourceDescription);
          this.onChanges();
          this.changeDisable = true;
        }, 100);
      } else {
        this.errorMessage = 'No Record Found.';
        this.userData = null;
      }
      this.isLogsCallFired = {
        loginLogsData: false,
        registrationLogsData: false,
        communicationPreferenceLogData: false,
      };
      this.resetTabs();
    },
      (err: HttpErrorResponse) => {
        this.errorMessage = err.message;
        this.resetTabs();
      });
  }

  private resetTabs() {
    this.dashboardParam = null;
    this.accountsParam = null;
    this.communicationLogFilterParam = null;
    this.productParam = null;
    this.welcomeTextParam = null;
    this.paymentParams = null;
    this.insuranceParams = null;
    this.campaignParam = null;
    this.leaderboardBannerParam = null;
  }

  updateUser() {
    this.userService.updateUser(this.userForm.value).subscribe((data: any) => {
      this.successMsg = 'User Updated Successfully.';
      this.scrollToTop();
      this.userData = this.userForm.value;
      this.changeDisable = true;

    }, (err: HttpErrorResponse) => {
      this.warningMsg = err.message;
    });
  }
  // this method is used to active the update button after fetching data from database, so that updated information will send back
  onChanges(): void {
    this.userForm.valueChanges.subscribe(val => {
      this.changeDisable = false;
    });
  }
  // Active button click
  activeUserEvent(isActive) {
    this.userActiveOrDeactiveModel = {
      azureAdObjectId: this.userForm.value.azureAdObjectId,
      IsActive: isActive
    };
    this.userService.activeOrDeactiveUserEvent(this.userActiveOrDeactiveModel).subscribe((data: any) => {
      this.successMsg = 'User Status Updated Successfully.';
      this.userForm.patchValue({
        isActive
      });
      this.changeDisable = true;
      this.scrollToTop();
    },
      (err: HttpErrorResponse) => {
        this.warningMsg = err.error.join('<br>');
      });
  }

  getLoginLogs() {
    if (this.isLogsCallFired.loginLogsData) {
      return;
    }
    this.loginLogParam = { azureAdObjectId: this.userForm.value.azureAdObjectId };
    this.isLogsCallFired.loginLogsData = true;
  }

  getRegistrationLogs() {
    if (this.isLogsCallFired.registrationLogsData) {
      return;
    }
    this.registrationLogParam = { azureAdObjectId: this.userForm.value.azureAdObjectId, userId: this.userId };
    this.isLogsCallFired.registrationLogsData = true;
  }

  getCommunicationPreference() {
    if (this.isLogsCallFired.communicationPreferenceLogData) {
      return;
    }
    this.communicationPreferenceLogParam = { idNumber: this.userForm.value.idNumber };
    this.isLogsCallFired.communicationPreferenceLogData = true;
  }

  setCommunicationParam() {
    this.communicationParam = { idNumber: this.userForm.value.idNumber };
  }

  toNumber(val: any) {
    val = val || '';
    const valArr = val.toString().split('');
    const valFiltered = valArr.filter((x: number) => !isNaN(x));
    const valProcessed = valFiltered.join('').trim();
    return valProcessed;
  }

  private scrollToTop() {
    const serchPanelDiv = document.getElementById('serchpanel');
    serchPanelDiv.scrollTop = 0;
  }


  auditLogPopupOnUserStatusChange() {
    this.auditLogParam = {
      idNumber: this.userForm.value.idNumber,
      auditLogTypeId: !this.userForm.value.isActive ? AuditLogType.UserActivation : AuditLogType.UserDeactivation,
      currentData: JSON.stringify({ isActive: this.userForm.value.isActive }),
      updatedData: JSON.stringify({ isActive: !this.userForm.value.isActive })
    };
    if (this.auditLogParam.auditLogTypeId === AuditLogType.UserActivation) {
      this.userAuditLogModelTitle = 'User Activation Log';
    } else if (this.auditLogParam.auditLogTypeId === AuditLogType.UserDeactivation) {
      this.userAuditLogModelTitle = 'User Deactivation Log';
    }
    this.modalService.open(this.userAuditLogModel, this.modalOptions);

  }

  auditLogComponentCallback(event) {
    this.modalService.dismissAll();
    if (this.auditLogParam.auditLogTypeId === AuditLogType.UserActivation ||
      this.auditLogParam.auditLogTypeId === AuditLogType.UserDeactivation) {
      this.activeUserEvent(!this.userForm.value.isActive);
    } else if (this.auditLogParam.auditLogTypeId === AuditLogType.UserProfileUpdate) {
      this.updateUser();
    }
  }

  getDashboard() {
    this.isCreditDataAvailable = true;
    let isActive = this.userForm.controls.isActive.value;
    const param = { idNumber: this.userForm.value.idNumber };
    this.dashboardService.CheckCreditHistoryCurrentMonth(param).subscribe((data: any) => {

      if (!data.isDataAvailable && !data.isCurrentMonth) {

        this.modalService.open(this.creditHistoryUpdate, this.modalOptions);
      }
      else if (data.isDataAvailable && !data.isCurrentMonth) {
        this.modalService.open(this.creditHistoryUpdate, this.modalOptions);
      }
      else if (!data.isDataAvailable && data.isCurrentMonth) {
        this.bindDashboard();
      }
      else {
        this.bindDashboard();
      }
      //else if (!isActive && !data.isDataAvailable && this.isExternalLead) {
      //this.updateCreditHistory();
      //}

    },
      (err: HttpErrorResponse) => {
        this.errorMessage = err.message;
      });
  }

  updateCreditHistory() {
    const data = { IdNumber: this.userForm.value.idNumber };
    this.modalService.dismissAll();
    this.dashboardService.UpdateCreditHistoryCurrentMonth(data).subscribe((data: any) => {

      if (data !== null) {
        this.bindDashboard();
      }
    },
      (err: HttpErrorResponse) => {
        this.errorMessage = err.message;
      });
  }

  closeCreditHistoryModal() {
    this.modalService.dismissAll();
    if (this.isCreditDataAvailable)
      this.bindDashboard();
  }

  bindDashboard() {
    this.dashboardParam = { idNumber: this.userForm.value.idNumber };
    this.isAccountsFired = false;
    this.isProductFired = false;
    this.communicationLogFilterParam = false;
    this.welcomeTextParam = false;
    this.isPaymentFired = false;
    this.isInsuranceFired = false;
    this.campaignParam = false;
  }

  bindCommunicationReport() {
    this.communicationLogFilterParam = true;
    this.dashboardParam = false;
    this.welcomeTextParam = false;
  }

  getAccounts() {
    if (this.isAccountsFired) {
      return;
    }
    this.accountsParam = { idNumber: this.userForm.value.idNumber, dbcUserType: this.dbcUserType };
    this.isAccountsFired = true;
  }

  getPayment() {
    if (this.isPaymentFired) {
      return;
    }
    this.paymentParams = { idNumber: this.userForm.value.idNumber };
    this.isPaymentFired = true;
  }
  getProducts() {
    if (this.isProductFired) {
      return;
    }
    this.registrationLogParam = { idNumber: this.userForm.value.idNumber };
    this.isProductFired = true;
  }
  getInsurance() {
    if (this.isInsuranceFired) {
      return;
    }
    this.insuranceParams = { idNumber: this.userForm.value.idNumber };
    this.isInsuranceFired = true;
  }

  auditLogPopupOnUserUpdate() {
    this.warningMsg = '';
    this.successMsg = '';
    this.errorMessage = '';

    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      return;
    }
 
    const grossIncomeName = 'grossIncome';
    let grossIncome: any = '';
    if (this.toNumber(this.userForm.controls.grossIncome.value) >= 0) {
      grossIncome = parseFloat(this.toNumber(this.userForm.controls.grossIncome.value));
    }
    this.userForm.controls[grossIncomeName].setValue((!isNaN(grossIncome)) ? grossIncome : null);

    this.auditLogParam = {
      idNumber: this.userForm.value.idNumber,
      auditLogTypeId: AuditLogType.UserProfileUpdate,
      currentData: JSON.stringify(this.userData),
      updatedData: JSON.stringify(this.userForm.value)
    };
    this.userAuditLogModelTitle = 'User Update Log';

    if (this.userForm.controls.phoneNumber.value != this.oldPhoneNumber) {
      this.newPhoneNumber = this.userForm.controls.phoneNumber.value;
      this.btnOTPIsDisabled = false; 
      this.otpErrorMsg = '';
      this.otpSuccessMsg = ''; 
      this.sendOtpText = 'Send OTP';
      this.otpPopupHeaderText ='Fill OTP';
      this.isOtpSent = false;
      this.modalService.open(this.optModel, this.modalOptions);
      return;
    }

    this.modalService.open(this.userAuditLogModel, this.modalOptions);
  }

  updateLookupForWebApi() {
    this.dataLookupService.updateLookupForWebApi().subscribe((data: any) => {
      this.lookupMsg = 'WebApi Lookups Updated Successfully';
      this.lookupErrorMsg = '';
    }, (err: HttpErrorResponse) => {
      this.lookupErrorMsg = err.message;
      this.lookupMsg = '';
    });
  }

  updateLookupForOpsApi() {
    this.dataLookupService.updateLookupForOpsApi().subscribe((data: any) => {
      this.lookupMsg = 'OpsApi Lookups Updated Successfully';
      this.lookupErrorMsg = '';
    }, (err: HttpErrorResponse) => {
      this.lookupErrorMsg = err.message;
      this.lookupMsg = '';
    });
  }

  uploadActiveUsersToSftp() {
    this.welcomeTextParam = false;
    this.lookupMsg = '';
    this.lookupErrorMsg = '';
    this.bureauBatchService.uploadActiveUsersToSftp().subscribe((data: any) => {
      if (data === 1) {
        this.lookupMsg = 'Active Users List Successfully Uploaded to Sftp';
      } else if (data === 2) {
        this.lookupErrorMsg = 'Active Users List Already Uploaded to Sftp';
      } else {
        this.lookupErrorMsg = 'Active Users List to Sftp has been Failed';
      }
    }, (err: HttpErrorResponse) => {
      this.lookupErrorMsg = err.message;
    });
  }

  processBureauBatchFileFromSftp() {
    if (this.sftpPathForm.invalid) {
      this.sftpPathForm.markAllAsTouched();
      return;
    }

    this.modalService.dismissAll();
    this.bureauBatchService.processBureauBatchFileFromSftp(this.sftpPathForm.value).subscribe((data: any) => {
      this.lookupMsg = 'Bureau Batch File Processed Successfully';
      this.lookupErrorMsg = '';
    }, (err: HttpErrorResponse) => {
      this.lookupErrorMsg = err.message;
      this.lookupMsg = '';
    });
  }

  updateExistingUserFields() {
    this.userService.updateExistingUserFields().subscribe((data: any) => {
      this.lookupMsg = 'Users updated Successfully';
      this.lookupErrorMsg = '';
    }, (err: HttpErrorResponse) => {
      this.lookupErrorMsg = err.message;
      this.lookupMsg = '';
    });
  }

  openSftpPathModel() {
    this.modalService.open(this.sftpPathModel, this.modalOptions);
    this.welcomeTextParam = false;
  }

  transferToSplPopup() {
    this.modalService.open(this.transferToSplConfirmationModel, this.modalOptions);
  }

  transferUserToSpl() {
    this.splErrorMsg = '';
    this.splSuccessMsg = '';
    const param = { IdNumber: this.userForm.value.idNumber };
    this.communicationService.transferUserToSpl(param).subscribe((data: any) => {
      // tslint:disable-next-line: max-line-length
      if (data && data['soap:Envelope'] && data['soap:Envelope']['soap:Body'] && data['soap:Envelope']['soap:Body']['tns:CreateLead_FromRequest_HDResponse'] && data['soap:Envelope']['soap:Body']['tns:CreateLead_FromRequest_HDResponse'].WebServiceMessage) {
        if (data['soap:Envelope']['soap:Body']['tns:CreateLead_FromRequest_HDResponse'].WebServiceMessage.Success === 'true') {
          this.splSuccessMsg = 'User Transferred to SPL';
        } else {
          this.splErrorMsg = data['soap:Envelope']['soap:Body']['tns:CreateLead_FromRequest_HDResponse'].WebServiceMessage.ErrorMessage;
        }
      } else {
        this.splErrorMsg = 'Error: Please try after some time';
      }
    },
      (err: HttpErrorResponse) => {
        this.splErrorMsg = 'Error: Please try after some time';
      });
  }

  bindWelcomeText() {
    this.welcomeTextParam = true;
    this.dashboardParam = false;
    this.communicationLogFilterParam = false;
    this.leaderboardBannerParam = false;
    this.campaignParam = false;
  }

  getCreditReport() {
    if (this.isCreditReportDetailFired) {
      return;
    }
    this.creditReportParam = { idNumber: this.userForm.value.idNumber };
    this.isCreditReportDetailFired = true;
  }


  get otpFormControls() { return this.otpForm.controls; }

  sendOtp() {
    this.otpErrorMsg = '';
    this.otpSuccessMsg = ''; 
    const param = {
      OtpType: 1, IdNumber: this.userForm.value.idNumber, PhoneNumber: this.newPhoneNumber
    };
     
    this.otpService.sendOtp(param).subscribe((data: any) => {
      this.otpPopupHeaderText = 'Your OTP has been sent to user, please enter it below';
      this.isOtpSent = true;

      if (this.sendOtpText === 'Resend SMS OTP') {
        this.otpSuccessMsg = 'Resend SMS OTP sent successfully';
      }
      else{
        this.sendOtpText = 'Resend SMS OTP';
      } 
    },
      (err: HttpErrorResponse) => {
        if (err.status === 429) {
          this.otpErrorMsg = 'OTP count exceeded, Try after some time';
        }
      });

  }

  validateOtp() {
    
    if (this.btnOTPIsDisabled) {
      return;
    } 
    this.btnOTPIsDisabled = true;
    this.otpErrorMsg = '';
    if (this.otpForm.invalid) {
      this.otpForm.markAllAsTouched();
      this.btnOTPIsDisabled = false; 
      return;
    } 
    const param = {
      Otp: parseInt(this.otpForm.value.Otp),
      OtpType: 1, IdNumber: this.userForm.value.idNumber, PhoneNumber: this.newPhoneNumber
    };
 
    this.otpService.validateOtp(param).subscribe((data: any) => {
      this.btnOTPIsDisabled = false; 
      this.otpSuccessMsg = 'phone number updated successfully';
      this.oldPhoneNumber = this.newPhoneNumber;
      setTimeout(()=> {
        this.otpForm.controls['Otp'].setValue('');
        this.modalService.dismissAll();
        this.modalService.open(this.userAuditLogModel, this.modalOptions);
      },3000);
    },
      (err: HttpErrorResponse) => {
        this.btnOTPIsDisabled = false;
        if (err.status === 422) {
          this.otpErrorMsg = 'Invalid OTP';
        }
      });

  }   


  isVerified() {
    var payload = {
      azureAdObjectId: this.userForm.value.azureAdObjectId
    };
    this.userService.isVerifiedUser(payload).subscribe((data: any) => {
      if (data.isVerified) {
        this.successMsg = 'User Is Verified Successfully.';
        this.userForm.patchValue({
          isVerified: data.isVerified,
          verifiedBy: data.verifiedBy,
          verifiedDate: data.verifiedDate,
        });
        this.userData = this.userForm.value;
        this.scrollToTop();
      }
    },
      (err: HttpErrorResponse) => {
        this.warningMsg = err.error.join('<br>');
      });
  }


  fetchFromBureauPopup(){
    this.creditReportYear = new Date().getFullYear();
    this.creditReportMonth = new Date().getMonth() + 1;
    const param = { year: this.creditReportYear, month: this.creditReportMonth, idNumber: this.userForm.value.idNumber };
    this.dashboardService.getIfCreditHistoryExists(param).subscribe((data: any) => {
      this.creditReportExits(data);
      this.modalService.open(this.fetchfromBureauModel, this.modalOptions);
    },
      (err: HttpErrorResponse) => {

      });
  }


  creditReportExits(creditHistoruExits: boolean){
    if(creditHistoruExits)
    {
      this.fetchFromBureauMessage = this.creditHistoryExists;
    }
    else
    {
      this.fetchFromBureauMessage = this.creditHistoryDoesNotExists;
    }

  }

  getCreditHistoryFromBureau(){  
    let element = document.getElementById('dashbord-tab') as HTMLElement
    this.ifCreditHistoryRequestByAgent = true;
    this.creditReportYear = new Date().getFullYear();
    this.creditReportMonth = new Date().getMonth() + 1;
    const param = { year: this.creditReportYear, month: this.creditReportMonth, idNumber: this.userForm.value.idNumber,role: this.userRole };
    this.dashboardService.getCreditHistoryFromBureau(param).subscribe((data: any) => {
      this.modalService.dismissAll();
      this.bindDashboard();
      this.ifCreditHistoryRequestByAgent = false;
      this.fetchFromBureauButtonClicked = false;
      if(element != null){
        element.click();
      }


    },
      (err: HttpErrorResponse) => {

      });
  }

  GetIsDataIsAvaible(isDataAvailable: boolean)
  {
    this.isCreditHistoryAvailble = isDataAvailable;
    console.log(isDataAvailable);
  }

  getDbcUserTypeText(dbcUserType) {
    switch(dbcUserType){
      case 1: return 'Debt Review Client';
      case 2: return 'General User';
      case 3: return 'Potential Client';
      default: return '';
    }
  }


  bindCampaign() {
    this.welcomeTextParam = false;
    this.dashboardParam = false;
    this.communicationLogFilterParam = false;
    this.leaderboardBannerParam = false;
    this.campaignParam = true;
  }

}
