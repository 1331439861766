export enum CommunicationLogReasonType {
    AfterhoursCall = 'Afterhours call',
    CrAdverseInformation = 'CR - Adverse Information',
    CrCreditScore = 'CR - Credit Score',
    CrDeclinedCredit = 'CR - Declined credit',
    CsuSecuredServices = 'CSU - Secured Services',
    CsuReality = 'CSU - Reality',
    CsuGeneral = 'CSU - General',
    CsuCovid19 = 'CSU - COVID 19',
    DebtCounselling = 'Debt Counselling',
    InsFuneral = 'Ins - Funeral',
    InsIncomeProtection = 'Ins - Income Protection',
    InsLife = 'Ins - Life',
    InsMedical = 'Ins - Medical',
    InsShortTerm = 'Ins - Short Term',
    CreditConsolidationLoan = 'Credit - Consolidation Loan',
    CreditCreditCard = 'Credit - Credit Card',
    CreditHousingFinance = 'Credit - Housing Finance',
    CreditPersonalLoan = 'Credit - Personal Loan',
    CreditVehicleFinance = 'Credit - Vehicle Finance',
    Registration = 'Registration',
}
export const CommunicationLogReasonTypeMapping = [
    { type: CommunicationLogReasonType.AfterhoursCall, value: 1 },
    { type: CommunicationLogReasonType.CrAdverseInformation, value: 2 },
    { type: CommunicationLogReasonType.CrCreditScore, value: 3 },
    { type: CommunicationLogReasonType.CrDeclinedCredit, value: 4 },
    { type: CommunicationLogReasonType.CsuSecuredServices, value: 5 },
    { type: CommunicationLogReasonType.CsuReality, value: 6 },
    { type: CommunicationLogReasonType.CsuGeneral, value: 7 },
    { type: CommunicationLogReasonType.CsuCovid19, value: 8 },
    { type: CommunicationLogReasonType.DebtCounselling, value: 9 },
    { type: CommunicationLogReasonType.InsFuneral, value: 10 },
    { type: CommunicationLogReasonType.InsIncomeProtection, value: 11 },
    { type: CommunicationLogReasonType.InsLife, value: 12 },
    { type: CommunicationLogReasonType.InsMedical, value: 13 },
    { type: CommunicationLogReasonType.InsShortTerm, value: 14 },
    { type: CommunicationLogReasonType.CreditConsolidationLoan, value: 15 },
    { type: CommunicationLogReasonType.CreditCreditCard, value: 16 },
    { type: CommunicationLogReasonType.CreditHousingFinance, value: 17 },
    { type: CommunicationLogReasonType.CreditPersonalLoan, value: 18 },
    { type: CommunicationLogReasonType.CreditVehicleFinance, value: 19 },
    { type: CommunicationLogReasonType.Registration, value: 20 }
];
