import { Component, OnInit } from '@angular/core';
import { CommunicationReportService } from '../../RxJs/communication-report.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { CommunicationLogTypeMapping } from '../../helpers/enums/communication-log-type';
import { CommunicationLogReasonTypeMapping } from '../../helpers/enums/communication-log-reason-type';
import { CommunicationLogTransferTypeMapping } from '../../helpers/enums/communication-log-transfer-type';



@Component({
  selector: 'app-communication-report',
  templateUrl: './communication-report.component.html',
  styleUrls: ['./communication-report.component.css']
})
export class CommunicationReportComponent implements OnInit {
paramData;
communicationLogList = [];
communicationLogTypes;
communicationLogTransferTypes;
communicationLogReasonTypes;
communicationLogFilterForm: FormGroup;
countLogs;
page = 0;
pageSize = 25;
 constructor(
    private communicationReportService: CommunicationReportService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.communicationLogTypes = CommunicationLogTypeMapping;
    this.communicationLogTransferTypes = CommunicationLogTransferTypeMapping;
    this.communicationLogReasonTypes =  CommunicationLogReasonTypeMapping;
    this.communicationLogFilterForm = this.formBuilder.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      transfer: ['']
    });
  }

  get communicationFormControls() { return this.communicationLogFilterForm.controls; }

  getCommReportDetails() {
    if (this.communicationLogFilterForm.invalid) {
      this.communicationLogFilterForm.markAllAsTouched();
      return;
    }
    this.communicationReportService.getCommunicationReport(this.communicationLogFilterForm.value).subscribe((data: any) => {
  console.log(data);
  this.communicationLogList = data.communicationLog;
  this.countLogs = data.totalLogs;
}, (err: HttpErrorResponse) => {

    });
}

  onToDateSelect(evt: any) {
    this.communicationLogFilterForm.patchValue({ toDate: new Date(evt.year, evt.month - 1, evt.day).toDateString()});
  }

  onFromDateSelect(evt: any) {
    this.communicationLogFilterForm.patchValue({ fromDate: new Date(evt.year, evt.month - 1, evt.day).toDateString()});
  }

}
