import { Component, OnInit,Input } from '@angular/core';
import { DashboardService } from 'src/app/RxJs/dashboard.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-credit-report-detail',
  templateUrl: './credit-report-detail.component.html',
  styleUrls: ['./credit-report-detail.component.css']
})
export class CreditReportDetailComponent implements OnInit {

  paramData;
  creditReportYear; creditReportMonth;
  isDataAvailable = null;
  creditResponseData = null;
  creditHistoryMonth = '';
  addressInformationResponse: any [];
  phoneInformationResponse: any [];
  employerInformationResponse : any [];
  securityQuestionAnswersResponseSetOne: any [];
  securityQuestionAnswersResponseSetTwo: any [];
  securityQuestionAnswerDetail :any [];
  
  constructor(private dashboardService: DashboardService) { }
  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
    this.bindCreditReportDetail();
  }

  ngOnInit() {
  }
  private bindCreditReportDetail() {
    this.creditReportYear = new Date().getFullYear();
    this.creditReportMonth = new Date().getMonth() + 1;
    this.creditHistoryMonth = new Date(`${new Date()}`).toLocaleDateString(undefined, { month: 'long' }) + ', ' + this.creditReportYear;
    this.getCreditReportDetail();
  }
  getCreditReportDetail() {
    const param = { year: this.creditReportYear, month: this.creditReportMonth, idNumber: this.paramData.idNumber };
    this.dashboardService.creditReportDetail(param).subscribe((data: any) => {
      this.creditResponseData = data;
      this.addressInformationResponse =  data.addressInformation;
      this.phoneInformationResponse = data.phoneInformation;
      this.employerInformationResponse = data.employerInformation;
      this.securityQuestionAnswersResponseSetOne = data.securityQuestionAnswersResponseSetOne;
      this.securityQuestionAnswersResponseSetTwo = data.securityQuestionAnswersResponseSetTwo;
      this.securityQuestionAnswerDetail = data.securityQuestionAnswer;
      console.log(this.securityQuestionAnswerDetail);
      if (data) {
        this.isDataAvailable = data.isDataAvailable;
        
      }
    },
      (err: HttpErrorResponse) => {

      });
  }


}
