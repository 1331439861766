import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.css']
})
export class ProgressIndicatorComponent implements OnInit {

  progressData;

  constructor() { }

  ngOnInit() {
  }

  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }

    let text = '';
    if (paramData.positiveUp && paramData.value >= 0) {
      text = 'Up';
    } else if (paramData.positiveUp && paramData.value < 0) {
      text = 'Down';
    } else if (!paramData.positiveUp && paramData.value >= 0) {
      text = 'Up';
    } else if (!paramData.positiveUp && paramData.value < 0) {
      text = 'Down';
    }

    let cssClass = '';
    if (paramData.positiveGreen && paramData.value >= 0) {
      cssClass = text.toLowerCase() + '-green';
    } else if (paramData.positiveGreen && paramData.value < 0) {
      cssClass = text.toLowerCase() + '-red';
    } else if (!paramData.positiveGreen && paramData.value >= 0) {
      cssClass = text.toLowerCase() + '-red';
    } else if (!paramData.positiveGreen && paramData.value < 0) {
      cssClass = text.toLowerCase() + '-green';
    }

    const indicatorValue = paramData.value < 0 ? (paramData.value * -1) : paramData.value;
    this.progressData = {
      realValue: paramData.value,
      value: (!paramData.isPercent ? 'R ' : '') + indicatorValue + (paramData.isPercent ? '%' : ''),
      text: paramData.value >= 0 ? 'Up' : 'Down',
      cssClass: 'value-score-' + cssClass + '-percent'
    };
  }
}
