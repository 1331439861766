export enum WelcomeTextStatus {
  Inactive = 0,
  Active = 1,
  All = 2
}

export const WelcomeTextStatusMapping = [
  { text: 'All', value: WelcomeTextStatus.All },
  { text: 'Inactive', value: WelcomeTextStatus.Inactive },
  { text: 'Active', value: WelcomeTextStatus.Active },

];