import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private adalService: MsAdalAngular6Service
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.adalService.accessToken) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }
}
