export const RegExValidation = {
  text: /[^a-zA-Z]*/g,
  textName: /[^a-zA-Z- ]*/g,
  numeric: /[^0-9]*/g,
  cellNumber: /^0[678][\d]{8}$/,
  password: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?& "%'()*+,-./:;<=>[\]^_`{|}~#])[A-Za-z\d$@$!%*?& "%'()*+,-./:;<=>[\]^_`{|}~#].{8,}$/,
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  numbersLettersAndUnderscore:  /^[\w_]*$/,
  numbersLettersUnderscoreAndSpace:  /^[\w_ ]*$/, 
  urlRegex: /^(https?|ftp):\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/
};
