import { Component, OnInit } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  userInfo;
  constructor(
    private adalService: MsAdalAngular6Service
  ) { }

  ngOnInit() {
    this.userInfo = this.adalService.userInfo;
    this.adalService.login();
  }

  login(): void {
    this.adalService.login();
  }

  logout(): void {
    this.adalService.logout();
  }

}
