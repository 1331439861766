export enum AuditLogUserDeactivationReasonType {
    PlatformWasNotHelpful = 2,
    PlatformWasNotEngaging = 3,
    PlatformDoesNotWork = 4,
    UserPrefersCompetitor = 5,
    UserDisagreeWithData = 6,
    CoachWasNotHelpful = 7
}

export const AuditLogUserDeactivationReasonTypeMapping = [
    { text: 'The platform wasn’t helpful', value: AuditLogUserDeactivationReasonType.PlatformWasNotHelpful },
    { text: 'The platform wasn’t engaging', value: AuditLogUserDeactivationReasonType.PlatformWasNotEngaging },
    { text: 'The platform doesn’t work', value: AuditLogUserDeactivationReasonType.PlatformDoesNotWork },
    { text: 'The user prefers a competitor', value: AuditLogUserDeactivationReasonType.UserPrefersCompetitor },
    { text: 'The user disagrees with the data', value: AuditLogUserDeactivationReasonType.UserDisagreeWithData },
    { text: 'The coach wasn’t helpful', value: AuditLogUserDeactivationReasonType.CoachWasNotHelpful }
  ];
