import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'node_modules/chart.js';
import * as $ from 'jquery';
import { DashboardService } from 'src/app/RxJs/dashboard.service';

@Component({
  selector: 'app-credit-score-history',
  templateUrl: './credit-score-history.component.html',
  styleUrls: ['./credit-score-history.component.css']
})
export class CreditScoreHistoryComponent implements OnInit {
  enableGraph: boolean = true;
  monthList: any;
  scoreList: any;
  scoreGraphData;
  progressIndicator: number;
  text;
  progressData;
  cssClass;
  display : boolean = false;
  hasData : boolean = null;
  registrationDate;
  paramData;

  constructor(private dashboardService: DashboardService)  { }
  @Input()
  set passDataInComponent(paramData) {    
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
  }
  ngOnInit() {
    this.getHistoricCreditScorePercentByIdnumber();
  }

  graphIntegration(scoreList, monthList) { 
    let data: any,
      options: any,
      chart: any,
      d = new Date(),
      ctx: any = document.getElementById('areaChart') as HTMLElement;

    

    data = {
      labels: monthList,
      datasets: [
        {
          label: 'Apples',
          data: scoreList,
          backgroundColor:'rgba(88, 173, 64, 0.1)' ,
          borderColor: 'rgba(88, 173, 64, 1)',
          
          pointBackgroundColor: 'rgba(88, 173, 64, 1)',
          fill: true,
          lineTension: 0,
          radius:4,
          pointHoverRadius: 10,
          pointHoverBackgroundColor: '#2b2b2b',
          pointHoverBorderColor:'rgba(157,183,180,0.6)',
          pointHoverBorderWidth:3,
        }
      ],
    };

    options = {
      responsive: true,
      layout: {
        padding: {
          right:50,
        }
      },
      scaleFontColor: "#FFFFFF",
        maintainAspectRatio: false,
        title: {
          display: false,
          maintainAspectRatio: false,
        },
        legend: {
          display: false,
          fontColor: "white",
          labels: {
            // This more specific font property overrides the global property
            fontColor: 'white',
            scaleFontColor: "#2B373F",
          }
        },
      tooltips: {
        caretPadding:20,
       // mode: 'index',
      //intersect: false,
        borderWidth:5,
        borderColor: '#2b2b2b',
        yAlign: 'top',
        xAlign: 'center',
        xPadding:25,
        yPadding:8,
        top:45,
        //titleAlign: 'center',
        //footerAlign: 'center',
        Font:{weight: 'bold'},
        bodyAlign: 'center',
        callbacks: {
          label: function (tooltipItem, data) {
            //let label = (tooltipItem.xLabel)
            let label2 = (tooltipItem.yLabel) + "%";
            return [
              //label, 
              label2];
          }
        },
        titleFontSize: 12,
        titleFontStyle: 'normal',
        displayColors: false,
        bodyFontColor: '#fff',
        bodyFontSize: 15,
        backgroundColor: '#2b2b2b',
        bodyFontStyle:'bold',

      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: "#2B373F",
            margin: 0,
            fontSize: 14,
            fontStyle: "normal",
            reverse:true,
          },
          gridLines: {
            color:  "rgba(231, 231, 231, 1)", display: true,
          },
          scaleLabel: {
            fontColor: "#2B373F"
          },
        }],
        yAxes: [{
          display: true,
          ticks: {
            min: 0,
            max: 100,
            stepSize: 20,
            fontColor: "#2B373F",
            margin: 100,
            fontSize: 14,
            fontStyle: "normal",
          },
          gridLines: {
            color:  "rgba(231, 231, 231, 1)", display: false,
          },
          scaleLabel: {
            fontColor: "#2B373F"
          }
        }]
      },
    };

    chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });
  }


  getHistoricCreditScorePercentByIdnumber() {
    const param = {  idNumber: this.paramData.idNumber };
    this.dashboardService.getCreditScorePercentChart(param).subscribe((data: any) => {
    console.log("CreditScoreResponse ", data.creditScoreResponse);
    
    if(data.creditScoreResponse.length === 0){
      this.hasData = false;
      this.registrationDate = data.registrationDate;
    }
    var _ct = this;
    this.removeDuplicateObject(data.creditScoreResponse, function(result){
      _ct.scoreGraphData = result;
      if (_ct.scoreGraphData.length === 0) {
        _ct.enableGraph = false;
      }
      else {
        _ct.monthList = _ct.scoreGraphData.map(a => { return (a.month + " " + a.year.toString().substr(2,2)) });
        _ct.scoreList = _ct.scoreGraphData.map(a => a.scorePercent);
        _ct.graphIntegration(_ct.scoreList, _ct.monthList);
      }
    });
   });
  }


  removeDuplicateObject(data,callback){
    var result = [];
      $.each(data, function (i, e) {
          var matchingItems = $.grep(result, function (item) {
            return item.year === e.year && item.month === e.month;
          });
          if (matchingItems.length === 0){
              result.push(e);
          }
      });
      callback(result);
  }

}
