import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  ngOnInit() {
    this.bindNavBar();
  }

  bindNavBar() {
    const hamburger = $('.panel-toogl');
    const customProfile = $('.customer-profile');
    
    $(hamburger).click(function(){
      $(customProfile).toggleClass("push-menu-hidden");
    });
  }


}
