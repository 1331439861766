import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from 'src/app/RxJs/product.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  productData: any = {};
  paramData;
  productExpand = {
    protectMoney: false,
    saveMoney: false,
  };
  constructor(private productService: ProductService) { }

  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
    this.getVccbPersonalLoanScore();
  }
  ngOnInit() { 
  }

  scrollToElement(id: any): void {
    const element = document.getElementById(id);
    if (element != null) {
      const offset = 100;
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }
  getVccbPersonalLoanScore() {
    const param = { idNumber: this.paramData.idNumber };
    this.productService.getVccbPersonalLoanScore(param).subscribe((data: any) => {
      this.productData = data;
    },
      (err: HttpErrorResponse) => {
      });
  }
}
