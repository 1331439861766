import { DatePipe, PlatformLocation, formatDate, CurrencyPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { InsuranceService } from 'src/app/RxJs/insurance.service';


@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {
  @ViewChild('mainInsuredDetail', { static: false }) mainInsuredDetail: ElementRef;
  @ViewChild('readDisclosures', { static: false }) readDisclosures: ElementRef;
  @ViewChild('lifeDisclosures', { static: false }) lifeDisclosures: ElementRef;
  @ViewChild('funeralDisclosures', { static: false }) funeralDisclosures: ElementRef;
  @ViewChild('legalInsurence', { static: false }) legalInsurence: ElementRef;
  @ViewChild('termPop', { static: false }) termPop: ElementRef;
  
  modalOptions: NgbModalOptions;
  creditLifeParam;lifeParam;funeralParam;mainViewParam = true;legalParam;
  paramData;
  successMsg = '';
  errorMsg = '';
  callbackErrorMsg = '';
  callbackSuccessMsg = '';
  dobUpto: Date = new Date();
  firstName = ''; lastName = ''; idNumber = ''; birthDate = ''; occupation = ''; 
  beneficiaryFirstName = ''; beneficiaryLastName = ''; beneficiaryRelationship = ''; beneficiaryDateOfBirth = '';
  hasCreditLifeCover = true; hasLifeCover = true; hasFuneralCover = true;  hasLegalCover= true;
  isCreditLifeCoverAccepted = true; isLifeCoverAccepted = true; isLegalCoverAccepted= true; isFuneralCoverAccepted = true; state = '';
  readDisclosure = false; readTerms = false;
  creditLifeCoverForm: FormGroup;
  lifeCoverForm: FormGroup;
  legalCoverForm:FormGroup;
  funeralCoverForm: FormGroup;
  negotiator: ''; acceptedDate : null;
  agreementSentDate: null; acceptanceMethod: null;
  spouseIdNumber:	null; spouseFirstName: null; spouseLastName: null;
  mainBeneficiaryDOBFromAPI; otherBeneficiaryDOBFromAPI; 
  mainBeneficiaryAge; clientInsuranceFetched = false;
  disclosureHtml = '';
  termsAndConditionHtml = ''; insuranceUpdatedDate;
  
  @Input()
  set passDataInComponent(paramData) { 
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
    //this.getPolicyHolderDetails();
  }

  constructor( 
    private modalService: NgbModal,
    private location: PlatformLocation,
    private insuranceService: InsuranceService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private currency: CurrencyPipe) { 
      this.modalOptions = {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
        keyboard: false
      };
      location.onPopState(() => this.modalService.dismissAll());
    }

  ngOnInit() {
    
    this.creditLifeCoverForm = this.formBuilder.group({
      leadID: [''],
      idNumber: [''],
      firstName: [''],
      lastName: [''],
      dob: [''],
      state: [''],
      negotiator: [''],
      acceptedDate: [''],
      agreementSentDate: [''],
      hasCreditLife: [''],
      hasLifeInsurance: [''],
      hasFuneral: [''],
      hasLegal:[''],
      policyNumber: [''],
      coverAmount: [''],
      rehabCovered: [''],
      creditLifePremium: [''],
      retrenchmentPremium: [''],
      totalPremium: [''],
      effectiveDate: [''],
      coveredAccounts: [],
      beneficiaryFirstName: [''],
      beneficiaryLastName: [''],
      beneficiaryDateOfBirth: [''],
      beneficiaryGender: [''],
      mainClientIDNumber: [''],
      beneficiaryRelationship: [''],
      isMainBeneficiary: [false],
      acceptTerms: [false],
      readTerms: [false]
    });

    this.lifeCoverForm = this.formBuilder.group({
      leadID: [''],
      idNumber: [''],
      firstName: [''],
      lastName: [''],
      dob: [''],
      state: [''],
      negotiator: [''],
      acceptedDate: [''],
      agreementSentDate: [''],
      hasCreditLife: [''],
      hasLifeInsurance: [''],
      hasLegal:[''],
      hasFuneral: [''],
      clientToComplete: [''],
      policyNumber: [''],
      coverAmount: [''],
      premium: [''],
      permanentDisabilityAmount: [''],
      dreadDiseaseAmount: [''],
      lifeInsuranceCreatedBy: [''],
      beneficiaryFirstName: [''],
      beneficiaryLastName: [''],
      beneficiaryDateOfBirth: [''],
      beneficiaryGender: [''],
      mainClientIDNumber: [''],
      beneficiaryRelationship: [''],
      sharePercentage: [''],
      isMainBeneficiary: [false],
      acceptTerms: [false],
      readTerms: [false]
    });

    this.legalCoverForm =this.formBuilder.group({
      leadID: [''],
      idNumber: [''],
      spouseIdNumber: [''],
      spouseDOB:[''],
      spouseFirstName: [''],
      spouseLastName: [''],
      hasCreditLife: [''],
      hasLifeInsurance: [''],
      hasLegal:[''],
      hasFuneral: [''],
      policyNumber: [''],
      premium: [''],
      criminalChargesAmount:[''],
      civilActionAmount: [''],
      labourMattersAmount:[''],
      maximumBenefitAmountPerInsuredMatter:[''],
      annualLimit:[''],
      overallBenefitLimit:[''],
      planOption: [''],
      clientToComplete:[''],
      acceptTerms: [false, Validators.pattern('true')],
      readTerms: [false, Validators.pattern('true')]
    });

    this.funeralCoverForm = this.formBuilder.group({
      leadID: [''],
      idNumber: [''],
      firstName: [''],
      lastName: [''],
      dob: [''],
      state: [''],
      negotiator: [''],
      acceptedDate: [''],
      agreementSentDate: [''],
      hasCreditLife: [''],
      hasLifeInsurance: [''],
      hasLegal:[''],
      hasFuneral: [''],
      clientToComplete: [''],
      funeralScheme:  [''],
      policyNumber: [''],
      coverAmount: [''],
      premium: [''],
      funeralDate: [''],
      funeralFirstPayDate: [''],
      peopleCovered: [''],
      ageOfOldest: [''],
      beneficiaryFirstName: [''],
      beneficiaryFirstNameExist: [false],
      beneficiaryLastName: [''],
      beneficiaryLastNameExist: [false],
      beneficiaryDateOfBirth: [''],
      beneficiaryDateOfBirthExist: [false],
      beneficiaryGender: [''],
      beneficiaryGenderExist: [false],
      mainClientIDNumber: [''],
      beneficiaryRelationship: [''],
      beneficiaryRelationshipExist: [false],
      sharePercentage: [''],
      isMainBeneficiary: [true],
      acceptTerms: [false],
      readTerms: [false],
      beneficiaryOthers: this.formBuilder.array([]),
      beneficiaryOthersCount: [0],
      beneficiaryAge: ['']
    });

    this.getPolicyHolderDetails();
  }

  
  get creditLifeCoverFormControls() { return this.creditLifeCoverForm.controls; }
  get lifeCoverFormControls() { return this.lifeCoverForm.controls; }
  get funeralCoverFormControls() { return this.funeralCoverForm.controls; }
  get legalCoverFormControls() { return this.legalCoverForm.controls; }
  
    get beneficiaryOthersFormControls(): FormArray{
    return <FormArray> this.funeralCoverForm.get('beneficiaryOthers');
  }

  private scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  getPolicyHolderDetails() {
    const param = {  idNumber: this.paramData.idNumber };
    this.insuranceService.getPolicyHolderDetails(param).subscribe((data: any) => {
      if(data != null){    
        this.clientInsuranceFetched = true;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.birthDate = data.dob;
        this.idNumber = data.idNumber;   
        this.state = data.state;
        this.negotiator = data.negotiator;
        this.acceptedDate = data.acceptedDate;
        this.agreementSentDate = data.agreementSentDate;
        this.acceptanceMethod = data.acceptanceMethod;
        this.spouseIdNumber =	data.spouseIdNumber;
        this.spouseFirstName = data.spouseFirstName; 
        this.spouseLastName = data.spouseLastName;
        this.hasCreditLifeCover = data.hasCreditLife;
        this.hasLifeCover = data.hasLifeInsurance;
        this.hasLegalCover = data.hasLegal;
        this.hasFuneralCover = data.hasFuneral;
        this.isCreditLifeCoverAccepted = data.isCreditLifeCoverAccepted;
        this.isLifeCoverAccepted = data.isLifeCoverAccepted;
        this.isLegalCoverAccepted = data.isLegalCoverAccepted;
        this.isFuneralCoverAccepted = data.isFuneralCoverAccepted;
        this.insuranceUpdatedDate = data.updatedDate;

        if(this.hasCreditLifeCover)
        {
          this.creditLifeCoverForm.patchValue({
            leadID: data.leadID,
            idNumber: data.idNumber,
            firstName: data.firstName,
            lastName: data.lastName,
            dob: data.dob,
            state: data.state,
            negotiator: data.negotiator,
            acceptedDate: data.acceptedDate,
            agreementSentDate: data.agreementSentDate,
            hasCreditLife: data.hasCreditLife,
            hasLifeInsurance: data.hasLifeInsurance,
            hasFuneral: data.hasFuneral,
            policyNumber: data.creditLife.policyNumber,
            coverAmount: data.creditLife.coverAmount,
            rehabCovered: data.creditLife.rehabCovered,
            creditLifePremium: data.creditLife.creditLifePremium,
            retrenchmentPremium: data.creditLife.retrenchmentPremium,
            totalPremium: data.creditLife.totalPremium,
            effectiveDate: data.creditLife.effectiveDate,
            coveredAccounts: data.creditLife.coveredAccounts,
            readTerms: data.isCreditLifeCoverAccepted || data.state == "Accepted",
            acceptTerms: data.isCreditLifeCoverAccepted || data.state == "Accepted"
          });
    
          if(data.creditLife.creditLifeBeneficiary.length > 0) {
            this.creditLifeCoverForm.patchValue({
              beneficiaryFirstName: data.creditLife.creditLifeBeneficiary[0].firstName,
              beneficiaryLastName: data.creditLife.creditLifeBeneficiary[0].lastName,
              beneficiaryDateOfBirth: data.creditLife.creditLifeBeneficiary[0].birthDate,
              beneficiaryGender: data.creditLife.creditLifeBeneficiary[0].gender,
              mainClientIDNumber: data.creditLife.creditLifeBeneficiary[0].mainClientIDNumber,
              beneficiaryRelationship: data.creditLife.creditLifeBeneficiary[0].relationship,
              isMainBeneficiary: data.creditLife.creditLifeBeneficiary[0].isMainBeneficiary
            });
          }
    
        }
  
        if(this.hasLifeCover)
        {
          this.lifeCoverForm.patchValue({
            leadID: data.leadID,
            idNumber: data.idNumber,
            firstName: data.firstName,
            lastName: data.lastName,
            dob: data.dob,
            state: data.state,
            negotiator: data.negotiator,
            acceptedDate: data.acceptedDate,
            agreementSentDate: data.agreementSentDate,
            hasCreditLife: data.hasCreditLife,
            hasLifeInsurance: data.hasLifeInsurance,
            hasFuneral: data.hasFuneral,
            clientToComplete: data.lifeInsurance.clientToComplete,
            policyNumber: data.lifeInsurance.policyNumber,
            coverAmount: data.lifeInsurance.coverAmount,
            premium: data.lifeInsurance.premium,
            permanentDisabilityAmount: data.lifeInsurance.permanentDisabilityAmount,
            dreadDiseaseAmount: data.lifeInsurance.dreadDiseaseAmount,
            lifeInsuranceCreatedBy: data.lifeInsurance.createdBy,
            readTerms: data.isLifeCoverAccepted || data.state == "Accepted",
            acceptTerms: data.isLifeCoverAccepted || data.state == "Accepted"
          });
    
          if(data.lifeInsurance.lifeBeneficiary.length > 0)
          {
            this.lifeCoverForm.patchValue({
              beneficiaryFirstName: data.lifeInsurance.lifeBeneficiary[0].firstName,
              beneficiaryLastName: data.lifeInsurance.lifeBeneficiary[0].lastName,
              beneficiaryDateOfBirth: data.lifeInsurance.lifeBeneficiary[0].birthDate,
              beneficiaryGender: data.lifeInsurance.lifeBeneficiary[0].gender,
              mainClientIDNumber: data.lifeInsurance.lifeBeneficiary[0].mainClientIDNumber,
              beneficiaryRelationship: data.lifeInsurance.lifeBeneficiary[0].relationship,
              sharePercentage: data.lifeInsurance.lifeBeneficiary[0].sharePercentage,
              isMainBeneficiary: data.lifeInsurance.lifeBeneficiary[0].isMainBeneficiary,
            });
          }
        }

        if(data.legal !=null)
        {
          this.legalCoverForm.patchValue({
            leadID: data.leadID,
            idNumber: data.idNumber,
            spouseIdNumber: data.spouseIdNumber,
            spouseFirstName: data.spouseFirstName,
            spouseLastName: data.spouseLastName,
            hasCreditLife: data.hasCreditLife,
            hasLifeInsurance: data.hasLifeInsurance,
            hasFuneral: data.hasFuneral,
            policyNumber: data.legal.policyNumber,
            premium: data.legal.premium,
            criminalChargesAmount: data.legal.criminalChargesAmount,
            civilActionAmount: data.legal.civilActionAmount,
            labourMattersAmount: data.legal.labourMattersAmount,
            maximumBenefitAmountPerInsuredMatter: data.legal.maximumBenefitAmountPerInsuredMatter,
            annualLimit: data.legal.annualLimit,
            overallBenefitLimit: data.legal.overallBenefitLimit,
            planOption: data.legal.planOption,
            hasLegal: data.hasLegal,
            clientToComplete: data.lifeInsurance.clientToComplete,
            readTerms: false,
            spouseDOB: data.spouseIdNumber ? this.idToDobFormat(data.spouseIdNumber.substring(0, 6)) : null, 
          });
        }
  
        if(this.hasFuneralCover)
        {
          this.funeralCoverForm.patchValue({
            leadID: data.leadID,
            idNumber: data.idNumber,
            firstName: data.firstName,
            lastName: data.lastName,
            dob: data.dob,
            state: data.state,
            negotiator: data.negotiator,
            acceptedDate: data.acceptedDate,
            agreementSentDate: data.agreementSentDate,
            hasCreditLife: data.hasCreditLife,
            hasLifeInsurance: data.hasLifeInsurance,
            hasFuneral: data.hasFuneral,
            clientToComplete: data.funeral.clientToComplete,
            funeralScheme: data.funeral.funeralScheme,
            policyNumber: data.funeral.policyNumber,
            coverAmount: data.funeral.coverAmount,
            premium: data.funeral.premium,
            funeralDate: data.funeral.funeralDate,
            funeralFirstPayDate: data.funeral.funeralFirstPayDate,
            peopleCovered: data.funeral.peopleCovered,
            ageOfOldest: data.funeral.ageOfOldest,
            readTerms: data.isFuneralCoverAccepted || data.state == "Accepted",
            acceptTerms: data.isFuneralCoverAccepted || data.state == "Accepted"
          });

        var funeralBeneficiaryMain = null;
        var funeralBeneficiaryOther = null;
        var funeralBeneficiaryOtherCount = null;

        if (data.funeral != null)
        {
          if(data.funeral.funeralBeneficiary != null)
          {           
            funeralBeneficiaryMain = data.funeral.funeralBeneficiary.find(record => record.isMainBeneficiary === true);
            funeralBeneficiaryOther = data.funeral.funeralBeneficiary.filter(record => record.isMainBeneficiary === false);
            funeralBeneficiaryOtherCount = data.funeral.funeralBeneficiary.filter(record => record.isMainBeneficiary === false).length;
          }
        }
    
          if(funeralBeneficiaryMain != null) {
            this.funeralCoverForm.patchValue({
              beneficiaryFirstName: funeralBeneficiaryMain.firstName,
              beneficiaryFirstNameExist: funeralBeneficiaryMain.firstName != '',
              beneficiaryLastName: funeralBeneficiaryMain.lastName,
              beneficiaryLastNameExist: funeralBeneficiaryMain.lastName != '',
              beneficiaryDateOfBirth:  this.getDOB(funeralBeneficiaryMain.birthDate),
              beneficiaryDateOfBirthExist: funeralBeneficiaryMain.birthDate != '',
              beneficiaryGender: funeralBeneficiaryMain.gender,
              beneficiaryGenderExist: funeralBeneficiaryMain.gender != '',
              mainClientIDNumber: funeralBeneficiaryMain.mainClientIDNumber,
              beneficiaryRelationship: funeralBeneficiaryMain.relationship,
              beneficiaryRelationshipExist: funeralBeneficiaryMain.relationship != '',
              sharePercentage: funeralBeneficiaryMain.sharePercentage,
              isMainBeneficiary: funeralBeneficiaryMain.isMainBeneficiary,
              beneficiaryAge: this.getAge(funeralBeneficiaryMain.age,funeralBeneficiaryMain.birthDate)
            });
    
            this.mainBeneficiaryAge = this.getAge(funeralBeneficiaryMain.age,funeralBeneficiaryMain.birthDate);
            this.mainBeneficiaryDOBFromAPI = this.getDOB(funeralBeneficiaryMain.birthDate);
          }
    
          if(funeralBeneficiaryOtherCount > 0 && data.funeral.peopleCovered > 1) {
            for(let val of funeralBeneficiaryOther) {
              this.beneficiaryOthersFormControls.push(this.formBuilder.group({
                firstName: [val.firstName, Validators.required],
                lastName: [val.lastName, Validators.required],
                birthDate: [this.getDOB(val.birthDate), Validators.required],
                gender: [val.gender, Validators.required],
                mainClientIDNumber: [val.mainClientIDNumber],
                relationship: [val.relationship, Validators.required],
                sharePercentage: [val.sharePercentage],
                isMainBeneficiary: [val.isMainBeneficiary],
                age: [this.getAge(val.age, val.birthDate)]
              }));
            }
            this.otherBeneficiaryDOBFromAPI = data.funeralBeneficiaryOther;
          }
    
        }

        if(data.isFuneralCoverAccepted) {      
          this.funeralCoverForm.patchValue({
            readTerms: true,
            acceptTerms: true
          });
        }
        if(data.isCreditLifeCoverAccepted) {
          this.creditLifeCoverForm.patchValue({
            readTerms: true,
            acceptTerms: true
          });
        }
        
        if (this.isLegalCoverAccepted) {
          this.legalCoverForm.patchValue({
            readTerms: true,
            acceptTerms: true,
          });
        }
  
        if(data.isLifeCoverAccepted) {
            this.lifeCoverForm.patchValue({
              readTerms: true,
              acceptTerms: true
        });
        }
       
      }
      else{
        this.clientInsuranceFetched = false;
      }

    });
  }

  idToDobFormat(id: string): string {
    const year = id.slice(0, 2);
    const month = id.slice(2, 4);
    const day = id.slice(4, 6);
  
    return `${year}/${month}/${day}`;
  }

  insure(){
    this.modalService.open(this.mainInsuredDetail,{ size: 'lg', backdrop: 'static' } );
  }

  mainview(){
    this.mainViewParam = true;
    this.creditLifeParam = false;
    this.lifeParam = false;
    this.funeralParam = false;
    this.legalParam = false;
  }

  creditLifeForm(){
    
    this.successMsg = this.errorMsg = "";
    this.readDisclosure = this.readTerms = true;
    this.creditLifeParam = true;
    this.lifeParam = false;
    this.mainViewParam = false;
    this.funeralParam = false;
    this.legalParam = false;
    $('.commonhold').hide();
    this.scrollToTop();
  }
  disclosures() {
    this.readDisclosure = true;
    this.getInsurancePolicyDetails(1);
    this.modalService.open(this.readDisclosures,{ size: 'xl', backdrop: 'static' } );
  }

  lifeForm(){
    this.successMsg = this.errorMsg = '';
    this.readDisclosure = this.readTerms = true;
    this.creditLifeParam = false;
    this.lifeParam = true;
    this.funeralParam = false;
    this.mainViewParam = false;
    this.legalParam = false;
    $('.commonhold').hide();
    this.scrollToTop();
  }
  legalForm() 
  {
    this.successMsg = this.errorMsg = '';
    this.readDisclosure = this.readTerms = true;
    this.creditLifeParam = false;
    this.lifeParam = false;
    this.funeralParam = false;
    this.mainViewParam = false;
    this.legalParam = true;
    $('.commonhold').hide();
    this.scrollToTop();
  }

  funeraldisclosures() {
    this.readDisclosure = true;
    this.getInsurancePolicyDetails(3);
    this.modalService.open(this.funeralDisclosures,{ size: 'xl', backdrop: 'static' } );
  }
  lifedisclosures() {
    this.readDisclosure = true;
    this.getInsurancePolicyDetails(2);
    this.modalService.open(this.lifeDisclosures,{ size: 'xl', backdrop: 'static' } );
  }

  legaldisclosures(){
    this.readDisclosure = true;
    this.getInsurancePolicyDetails(4);
    this.modalService.open(this.legalInsurence,{ size: 'xl', backdrop: 'static' } );
  }

  funeralForm(){
    this.successMsg = this.errorMsg = "";
    this.readDisclosure = this.readTerms = true;
    this.creditLifeParam = false;
    this.lifeParam = false;
    this.funeralParam = true;
    this.mainViewParam = false;
    $('.commonhold').hide();
    this.scrollToTop();
  }

  terms() {
    this.readTerms = true;
    if(this.readDisclosure == true)
    {
      this.creditLifeCoverForm.patchValue({ readTerms: true})
      this.lifeCoverForm.patchValue({ readTerms: true})
      this.funeralCoverForm.patchValue({ readTerms: true})
    }
    this.getInsurancePolicyDetails(1);
    this.modalService.open(this.termPop,{ size: 'xl', backdrop: 'static' } );
    
  }

  
  getDOB(dob){
    if(dob != null) 
      return formatDate(dob, 'yyyy/MM/dd', 'en-US');
    return '';
  }
  getAge(age: any,dob: any){
    if(age != null)
      return age;
    else if(dob != null)
      return this.calculateAge(dob);
    else
      return '';
  }
  calculateAge(birthDate : any) {
    return moment().diff(birthDate, 'years');
  }

  getDateOfBirthFromSAID(idNumber: string): Date | null {
    // Check if the ID number is valid (length = 13)
    if (idNumber.length !== 13 || isNaN(Number(idNumber))) {
        return null;
    }

    const year = parseInt(idNumber.substr(0, 2));
    const month = parseInt(idNumber.substr(2, 2));
    const day = parseInt(idNumber.substr(4, 2));

    const currentYear = new Date().getFullYear();
    const prefix = year > currentYear % 100 ? 19 : 20; // Determine the prefix for the birth year

    const birthYear = prefix * 100 + year;
    const birthDate = new Date(birthYear, month - 1, day); // Months are zero-based in JavaScript Date

    return birthDate;
}
listenLifeDisclosures() {
  this.readDisclosure = true;
  if(this.readTerms == true)
  {
    this.lifeCoverForm.patchValue({ readTerms: true})
  }
}
listenFuneralDisclosures() {
  this.readDisclosure = true;
  if(this.readTerms == true)
  {
    this.funeralCoverForm.patchValue({ readTerms: true})
  }
} 
listenDisclosures() {
  this.readDisclosure = true;
  if(this.readTerms == true)
  {
    this.creditLifeCoverForm.patchValue({ readTerms: true})
  }
}
listenLegalDisclosures() {
  this.readDisclosure = true;
  if(this.readTerms == true)
  {
    this.legalCoverForm.patchValue({ readTerms: true})
  }
}

change(beneficiaryAge){ 
}
checkBoxClick(event: any) {
    console.log(event.target.checked);
}
getInsurancePolicyDetails(insuranceTypeId: number) {
  const param = 
  { idNumber: this.paramData.idNumber,
    insuranceTypeId: insuranceTypeId
  }
  this.insuranceService.getInsurancePolicyDetails(param).subscribe((data: any) => {
    if(data != null) {
      console.log(data.policyDetailsHtml);
      this.disclosureHtml = data.policyDetailsHtml;
      this.termsAndConditionHtml = data.termsAndConditionHtml;

      this.updatePolicyDetailValues(insuranceTypeId);
    }
  }, (err: HttpErrorResponse) => {

  });
}
updatePolicyDetailValues(insuranceTypeId: number) {
  if(insuranceTypeId == 1) {
    var coveredAccountHtml = '<div class="table-responsive"> <table class="table table-sm"> <tbody>';
    for(let coveredAccount of this.creditLifeCoverFormControls.coveredAccounts.value) {
      coveredAccountHtml += '<tr><td>' + coveredAccount.creditor + '</td>' + 
      '<td>' + coveredAccount.accountNumber + '</td>' +
      '<td>' + coveredAccount.currentBalance + '</td>' +
      '<td>' + coveredAccount.currentInstallment + '</td></tr>';
    }
    coveredAccountHtml += '</tbody> </table> </div>';
    this.disclosureHtml = this.disclosureHtml.replace('#CoverAmount#', this.creditLifeCoverFormControls.coverAmount.value)
                            .replace('#RehabCovered#', this.creditLifeCoverFormControls.rehabCovered.value)
                            .replace('#BeneficiaryFirstName#', this.creditLifeCoverFormControls.beneficiaryFirstName.value)
                            .replace('#BeneficiaryLastName#', this.creditLifeCoverFormControls.beneficiaryLastName.value)
                            .replace('#BeneficiaryRelationship#', this.creditLifeCoverFormControls.beneficiaryRelationship.value)
                            .replace('#BeneficiaryDateOfBirth#', this.datePipe.transform(this.creditLifeCoverFormControls.beneficiaryDateOfBirth.value))
                            .replace('#PolicyHolderName#', this.creditLifeCoverFormControls.firstName.value + ' ' + this.creditLifeCoverFormControls.lastName.value)
                            .replace('#PolicyHolderIdNumber#', this.creditLifeCoverFormControls.idNumber.value)
                            .replace('#Negotiator#', this.creditLifeCoverFormControls.negotiator.value)
                            .replace('#CoveredAccount#', coveredAccountHtml);

  } else if(insuranceTypeId == 2) {
    this.disclosureHtml = this.disclosureHtml.replace('#CoverAmount#', this.lifeCoverFormControls.coverAmount.value)
                            .replace('#PermanentDisabilityAmount#', this.lifeCoverFormControls.permanentDisabilityAmount.value)
                            .replace('#DreadDiseaseAmount#', this.lifeCoverFormControls.dreadDiseaseAmount.value)
                            .replace('#Premium#', this.lifeCoverFormControls.premium.value)
                            .replace('#PolicyHolderName#', this.lifeCoverFormControls.firstName.value + ' ' + this.lifeCoverFormControls.lastName.value)
                            .replace('#PolicyHolderIdNumber#', this.lifeCoverFormControls.idNumber.value)
                            .replace('#Negotiator#', this.lifeCoverFormControls.negotiator.value);


  } else if(insuranceTypeId == 3) {
    var otherInsuredLivesHtml = '';

    for(let beneficiaryOther of this.funeralCoverFormControls.beneficiaryOthers.value) {
      otherInsuredLivesHtml += '<tr><td>' + beneficiaryOther.firstName + '</td>' +
        '<td>' + beneficiaryOther.lastName + '</td>' +
        '<td>' + beneficiaryOther.relationship + '</td>' +
        '<td>' + this.datePipe.transform(beneficiaryOther.birthDate) + '</td>' +
        '<td>' + this.calculateAge(beneficiaryOther.birthDate) + '</td>' +
        '<td>' + beneficiaryOther.gender + '</td>' +
        '<td>R ' + this.funeralCoverFormControls.coverAmount.value + '</td>';
        '<td>' + beneficiaryOther.age + '</td></tr>';
    }

    this.disclosureHtml = this.disclosureHtml.replace('#FuneralScheme#', this.funeralCoverFormControls.funeralScheme.value)
                            .replace('#PeopleCovered#', this.funeralCoverFormControls.peopleCovered.value)
                            .replace('#BeneficiaryFirstName#', this.funeralCoverFormControls.beneficiaryFirstName.value)
                            .replace('#BeneficiaryLastName#', this.funeralCoverFormControls.beneficiaryLastName.value)
                            .replace('#BeneficiaryRelationship#', this.funeralCoverFormControls.beneficiaryRelationship.value)
                            .replace('#BeneficiaryDateOfBirth#', this.datePipe.transform(this.funeralCoverFormControls.beneficiaryDateOfBirth.value))
                            .replace('#BeneficiaryAge#', this.calculateAge(this.funeralCoverFormControls.beneficiaryDateOfBirth.value).toString())
                            .replace('#BeneficiaryGender#', this.funeralCoverFormControls.beneficiaryGender.value)
                            .replace('#CoverAmount#', this.funeralCoverFormControls.coverAmount.value)
                            .replace('#Premium#', this.funeralCoverFormControls.premium.value)
                            .replace('#PolicyHolderName#', this.funeralCoverFormControls.firstName.value + ' ' + this.funeralCoverFormControls.lastName.value)
                            .replace('#PolicyHolderIdNumber#', this.funeralCoverFormControls.idNumber.value)
                            .replace('#Negotiator#', this.funeralCoverFormControls.negotiator.value)
                            .replace('#OtherInsuredLives#', otherInsuredLivesHtml);
                            
  }
  else if(insuranceTypeId == 4) {
    this.disclosureHtml = this.disclosureHtml.replace('#AnnualLimit#', this.legalCoverFormControls.annualLimit.value)
                            .replace('#OverallBenefitLimit#', this.legalCoverFormControls.overallBenefitLimit.value)
                            .replace('#SpouseIdNumber#', this.legalCoverFormControls.spouseIdNumber.value)
                            .replace('#Premium#', this.legalCoverFormControls.premium.value)
                            .replace('#SpouseFullName#', this.legalCoverFormControls.spouseFirstName.value + ' ' + this.legalCoverFormControls.spouseLastName.value)
                            .replace('#SpouseDOB#', this.legalCoverFormControls.spouseDOB.value)
                          
  }
}
}
