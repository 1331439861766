import { Component, OnInit } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  userInfo;
  constructor(
    private adalService: MsAdalAngular6Service
  ) { }

  ngOnInit() {
    this.userInfo = this.adalService.userInfo;
  }

  logout() {
    this.adalService.logout();
  }

}
