export enum AuditLogType {
  UserActivation = 1,
  UserDeactivation = 2,
  UserProfileUpdate = 3,
}

export const AuditLogTypeMapping = [
  { text: 'User Activation', value: AuditLogType.UserActivation },
  { text: 'User Deactivation', value: AuditLogType.UserDeactivation },
  { text: 'User Profile Update', value: AuditLogType.UserProfileUpdate },
];
