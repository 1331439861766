import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MsAdalAngular6Module, MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthGuard } from './auth/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputValidationService } from './helpers/validators/input-validation.service';
import { NameFieldHyphenSpaceDirective } from './helpers/directive/name-field-hyphen-space.directive';
import { CurrencyMaskDirective } from './helpers/directive/currency-mask.directive';
import { NameFieldDirective } from './helpers/directive/name-field.driective';
import { NumericFieldDirective } from './helpers/directive/numeric-field.directive';
import { LoginLogComponent } from './dashboard-components/login-log/login-log.component';
import { RegistrationLogComponent } from './dashboard-components/registration-log/registration-log.component';
import { CreditReportComponent } from './dashboard-components/credit-report/credit-report.component';
import { CommunicationLogComponent } from './dashboard-components/communication-log/communication-log.component';
import { AccountComponent } from './dashboard-components/account/account.component';
import { ProductsComponent } from './dashboard-components/products/products.component';
import { UserService } from './RxJs/user.service';
import { CommunicationLogService } from './RxJs/communication-log.service';
import { AuditLogService } from './RxJs/audit-log.service';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { DashboardService } from './RxJs/dashboard.service';
import { AccountService } from './RxJs/account.service';
import { DataLookupService } from './RxJs/data-lookup.service';
import { CommunicationReportComponent } from './dashboard-components/communication-report/communication-report.component';
import { CommunicationReportService} from './RxJs/communication-report.service';
import { BureauBatchService } from './RxJs/bureau-batch.service';
import { ParseCommunicationLogTypePipe } from './helpers/pipe/parse-communication-log-type.pipe';
import { ParseCommunicationLogReasonTypePipe } from './helpers/pipe/parse-communication-log-reason-type.pipe';
import { ParseCommunicationLogTransferTypePipe } from './helpers/pipe/parse-communication-log-transfer-type.pipe';
import { ProgressIndicatorComponent } from './dashboard-components/progress-indicator/progress-indicator.component';
import { CommunicationService } from './RxJs/communication.service';
import { ProductService} from './RxJs/product.service';
import { WelcomeTextComponent } from './dashboard-components/welcome-text/welcome-text.component';
import { CommunicationPreferenceComponent } from './dashboard-components/communication-preference/communication-preference.component';
import { CreditReportDetailComponent } from './dashboard-components/credit-report-detail/credit-report-detail.component';
import { OtpService } from './RxJs/otp.service';
import { CreditScoreHistoryComponent } from './dashboard-components/credit-score-history/credit-score-history.component';
import { PaymentsComponent } from './dashboard-components/payments/payments.component';
import { ParseAccountTypeClassPipe } from './helpers/pipe/parse-account-type-class.pipe';
import { InsuranceComponent } from './dashboard-components/insurance/insurance.component';
import { CampaignComponent } from './dashboard-components/campaign/campaign.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { CurrencyPipe, DatePipe } from '@angular/common';

export function msAdalAngular6ConfigFactory() {
  return {
    tenant: environment.azureAdB2BConfig.tenant,
    clientId: environment.azureAdB2BConfig.clientId,
    redirectUri: environment.azureAdB2BConfig.redirectUri,
    navigateToLoginRequestUrl: environment.azureAdB2BConfig.navigateToLoginRequestUrl,
    cacheLocation: environment.azureAdB2BConfig.cacheLocation,
    postLogoutRedirectUri: environment.azureAdB2BConfig.postLogoutRedirectUri,
  };
}

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 0,
  prefix: 'R ',
  suffix: '',
  thousands: ',',
  nullable: true
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LayoutComponent,
    DashboardComponent,
    NameFieldHyphenSpaceDirective,
    CurrencyMaskDirective,
    NumericFieldDirective,
    NameFieldDirective,
    LoginLogComponent,
    RegistrationLogComponent,
    CreditReportComponent,
    AccountComponent,
    ProductsComponent,
    CommunicationLogComponent,
    AuditLogComponent,
    CommunicationReportComponent,
    ParseCommunicationLogTypePipe,
    ParseCommunicationLogReasonTypePipe,
    ParseCommunicationLogTransferTypePipe,
    ProgressIndicatorComponent,
    WelcomeTextComponent,
    CommunicationPreferenceComponent, 
    CreditReportDetailComponent, CreditScoreHistoryComponent, PaymentsComponent, ParseAccountTypeClassPipe, InsuranceComponent, 
    CampaignComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NgbModule,
    MsAdalAngular6Module,
    CKEditorModule,
  ],
  exports: [
    CurrencyMaskDirective
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    UserService,
    CommunicationLogService,
    CommunicationReportService,
    InputValidationService,
    AuditLogService,
    DashboardService,
    AccountService,
    DataLookupService,
    BureauBatchService,
    AuthGuard,
    CommunicationService,
    ProductService,
    OtpService,
    DatePipe,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    MsAdalAngular6Service,
    {
      provide: 'adalConfig',
      useFactory: msAdalAngular6ConfigFactory,
      deps: []
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
