import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/RxJs/user.service';

@Component({
  selector: 'app-login-log',
  templateUrl: './login-log.component.html',
  styleUrls: ['./login-log.component.css']
})
export class LoginLogComponent implements OnInit {

  loginLogsData;

  constructor(
    private userService: UserService
  ) { }

  @Input()
  set passDataInComponent(paramData) {
    this.loginLogsData = null;
    if (!paramData) {
      return;
    }
    this.getLoginLogs(paramData);
  }

  getLoginLogs(paramData) {
    const param = { azureAdObjectId: paramData.azureAdObjectId };
    this.userService.getLoginLogs(param).subscribe((data: any) => {
      this.loginLogsData = data;
    },
      (err: HttpErrorResponse) => {
      });
  }

  ngOnInit() {
  }

}
