import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/RxJs/dashboard.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-credit-report',
  templateUrl: './credit-report.component.html',
  styleUrls: ['./credit-report.component.css']
})
export class CreditReportComponent implements OnInit {

  paramData;
  creditReportYear; creditReportMonth;
  creditHistory = null; creditHealthInfo: any = {}; creditHealthInfoUiTextElement: any = {};
  scoreStatus = ''; scoreProgress: any = 0;
  creditHistoryMonths; creditHistoryMonth = '';
  progressIndicator = {
    moneyLeftForExpenses: null,
    monthlyInterestPayment: null,
    salaryGoingTowardDebtPercent: null,
    scorePercent: null,
    totalOverdueAmount: null
  };
  scoreParam;

  constructor(private dashboardService: DashboardService) { }
  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
    this.bindDashboard();
    this.scoreParam = { idNumber: this.paramData.idNumber };
  }

  ngOnInit() {
  }

  private bindDashboard() {
    this.creditReportYear = new Date().getFullYear();
    this.creditReportMonth = new Date().getMonth() + 1;
    this.creditHistoryMonth = new Date(`${new Date()}`).toLocaleDateString(undefined, { month: 'long' }) + ', ' + this.creditReportYear;
    this.getCreditHistory(() => {
      this.getCreditHealthInfo();
    });
  }

  getCreditHistory(callback) {
    const param = { year: this.creditReportYear, month: this.creditReportMonth, idNumber: this.paramData.idNumber };
    this.dashboardService.getCreditHistoryFromDatabase(param).subscribe((data: any) => {
      this.creditHistory = data;
      if (data) {
        this.scoreProgress = data.scoreInformation.score;
        this.scoreStatus = data.uiTextElement.scoreType.replace(/([A-Z])/g, ' $1').trim();
        if (callback) {
          callback();
        }
      }
    },
      (err: HttpErrorResponse) => {

      });
  }

  getCreditHealthInfo() {
    const param = { year: this.creditReportYear, month: this.creditReportMonth, idNumber: this.paramData.idNumber };
    this.dashboardService.creditHealthInfo(param).subscribe((data: any) => {
      this.creditHealthInfo = data.creditHealthInfo;
      this.creditHealthInfoUiTextElement = data.creditHealthInfoUiTextResponse;
      this.setProgressIndicatorVariables(data.progressIndicator);
    },
      (err: HttpErrorResponse) => {

      });
  }

  setProgressIndicatorVariables(indicator) {
    if (!indicator) {
      this.progressIndicator = {
        moneyLeftForExpenses: null,
        monthlyInterestPayment: null,
        salaryGoingTowardDebtPercent: null,
        scorePercent: null,
        totalOverdueAmount: null
      };
    } else {
      this.progressIndicator = {
        moneyLeftForExpenses:
          { value: indicator.moneyLeftForExpenses, positiveUp: false, positiveGreen: true, isPercent: false },
        monthlyInterestPayment:
          { value: indicator.monthlyInterestPayment, positiveUp: true, positiveGreen: false, isPercent: false },
        salaryGoingTowardDebtPercent:
          { value: indicator.salaryGoingTowardDebtPercent, positiveUp: true, positiveGreen: false, isPercent: true },
        scorePercent:
          { value: indicator.scorePercent, positiveUp: true, positiveGreen: true, isPercent: true },
        totalOverdueAmount:
          { value: indicator.totalOverdueAmount, positiveUp: true, positiveGreen: false, isPercent: false }
      };
    }
  }

}
