import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseAccountTypeClass'
})
export class ParseAccountTypeClassPipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case 'Home Loan':
        return 'loan-account-home';
      case 'Vehicle Loan':
        return 'loan-account-car';
      case 'Vehicle':
          return 'loan-account-car';
      case 'Retail':
        return 'loan-account-retailer';
      case 'Clothing':
        return 'loan-account-retailer';        
      case 'Personal Loan / Microlender':
        return 'loan-account-personal-loan';    
      case 'Credit Card':
        return 'loan-account-cards';
      case 'Personal Loan':
        return 'loan-account-personal-loan';
      case 'Insurance':
        return 'loan-account-insurance';
      case 'Contracts':
        return 'loan-account-contract';
      case 'Bond':
        return 'loan-account-contract';
      case 'Furniture':
        return 'loan-account-cards';
      case 'Other':
        return 'loan-account-cards';
      default:
        return 'loan-account-bank';
    }
  }
}
