import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, MaxLengthValidator, Validators } from '@angular/forms';
import { NgbDate, NgbDateStruct, NgbModal, NgbModalOptions, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CampaignService } from 'src/app/RxJs/campaign.service';
import { LeaderboardbannerService } from 'src/app/RxJs/leaderboardbanner.service';
import { InputValidationService } from 'src/app/helpers/validators/input-validation.service';
import * as moment from 'moment';
import { Guid } from 'guid-typescript';




@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent implements OnInit {
  @ViewChild('campainLinkingModel', { static: false }) campainLinkingModel: ElementRef;
  @ViewChild('camModal', { static: false }) camModal: ElementRef;
  @ViewChild('EditModel', { static: false }) EditModel: ElementRef;
  @ViewChild('previewModel', { static: false }) previewModel: ElementRef;
  @ViewChild('newBannerModal', { static: false }) newBannerModal: ElementRef;
  @ViewChild('deleteModal', { static: false }) deleteModal: ElementRef;
  @ViewChild('activeWarningBannerModal', { static: false }) activeWarningBannerModal: ElementRef;
  @ViewChild('activeWarningCampaignsModal', { static: false }) activeWarningCampaignsModal: ElementRef;
  @ViewChild('deleteModalBanner', { static: false }) deleteModalBanner: ElementRef;
  @ViewChild('previewModelOverview', { static: false }) previewModelOverview: ElementRef;
  @ViewChild('ckeditor', { static: false }) ckeditor: any;

  paramData;
  campaignsList: any = null;
  isShown = true;

  campaignLookUpForm: FormGroup;
  bannerForm: FormGroup;

  leaderBoardBannerList = '';
  campaignCategoryList = '';
  editId;
  bannerId;

  minStartDate;
  minEndDate;
  minEditEndDate;
  maxStartDate = null;
  // expandCampaignTab: boolean = false;
  // expandBannerTab: boolean = false;
  beginDateModel: NgbDateStruct;
  endDateModel: NgbDateStruct;

  beginDateOnLoad;
  endDateOnLoad;
  bannerOnLoad = null;

  endDateAsReceived = null;


  UJCTabOpened: boolean = false;
  showDeleteWaringMessage: boolean = false;

  startdateStr;
  endDateStr;
  endDateValueBeforeToggle;
  successMsgCampaignLookup = '';
  warningMsgCampaignLookup = '';
  successMsgEditUpdateForm = '';
  warningMsgEditUpdateForm = '';
  successMsgDeletePopUp = '';
  warningMsgDeletePopUp = '';
  warningCampaignBannerBindingMsg = ''
  successCampaignBannerBindingMsg = ''
  modalOptions: NgbModalOptions;
  isInsertMode: boolean = true; //else- editMode
  isInsertModelBanner: boolean = true;
  isBannerUpdated: boolean = false;

  isAOngoingCampaign: boolean = null;

  successMsgLeaderBoardBanner = '';
  warningMsgLeaderBoardBanner = '';
  nextEndDate;
  nextBeginDate;

  successMsgLeaderBoardBannerDelete = '';
  warningMsgLeaderBoardBannerDelete = '';

  editDateForAOngoingCamapign: boolean = false;
  addBannerFromPopup: boolean = null;

  bannerAccordianHeading: string = "Add Leaderboard Banner";

  campaignIsLinkedToABanner: boolean = false;
  bannerIdLinkedToCampaign: Guid;

  leaderBoardBannerDateModified: boolean = false;
  modifyDatesOfCamapign: boolean = null;
  softDeleteId: number;
  buttonActionSelectHolder = false;
  buttonActionLinkHolder = false;
  buttonActionLmsHolder = false;
  showdeleteWarningMessage = false;
  deleteWarningMessage = 'Warning: You are making changes to a camapign that is currently active. Are you sure you want  to continue? This can not be reverted.';
  warningMessageLeaderboardNameModified = 'Warning: You are making changes to a banner that is currently active and in use. Once changes are made, it will immediately impact all banners in this campaign that is currently active. Do you wish to proceed?';
  warningMessageStartDateOrEndModified = 'Warning: You are making changes to a campaign that is currently active and in use. Once changes are made, it will be implemented  immediately. Please note that when changes are made to the Start or End Date, it might cause active campaigns to become inactive. Do you wish to proceed?';
  bannerHeading: string = '';
  bannerContent: string = '';
  bannerButtonDisplayName: string = '';
  recycleBinCampaignList;
  recycleLeaderBoardBannerList;
  successMsgDeletePopUpBanner = '';
  warningMsgDeletePopUpBanner = '';
  bannerHeadingOverView: string = '';
  bannerContentOverView: string = '';
  bannerButtonDisplayNameOverView: string = ''
  bannerLinkedToACamapaign: boolean = false;
  deleteBannerWarningMessage = 'Warning: You are trying to delete a banner that is currenty linked with a ongoing campaign. Once changes are made, it will immediately impact all banners in this campaign that is currently active. Please unlink the banner from the camapign and then delete the banner.'
  linkedCampaign = '';



  /**ck editor***/
  name = "ng2-ckeditor";
  ckeConfig: any;
  mycontent: string;
  CKEDITOR: any;
  config: any;

  // campaignForm:FormGroup;
  // ckeditorForm:FormGroup;
  // editUpdateForm:FormGroup;
  // ckeditorContent ='';
  // ckeditorContentSecondary='';
  // ckeditorprimarycontentsecondpage='';
  // showIDNumberTextBox:boolean=false;
  // enabledFieldsForCampaignSecond:boolean= false;
  // primaryHeading;
  // primaryRemaining;secondaryHeading;
  // secondaryRemaining;showCustomValidaton:boolean= false;
  // showCustomValidaton2:boolean= false;  

  constructor(
    private campaignService: CampaignService,
    private formBuilder: FormBuilder,
    configg: NgbModalConfig,
    private modalService: NgbModal,
    private leadeBoardBannerService: LeaderboardbannerService) {
    configg.backdrop = 'static';
    configg.keyboard = false;
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
    };
    this.config = {
      height: 450,
      toolbar: [
        // { name: 'insert', items: ['Templates'] },  
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
        //{ name: 'paragraph', items: ['NumberedList', 'BulletedList', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },

      ],
    }
    this.config.filebrowserUploadMethod = 'form',
      this.config.filebrowserBrowseUrl = '/portal/browseimages';
    this.config.image_previewText = ' ';
    this.config.allowedContent = true;
    this.config.templates_replaceContent = false;
    this.config.fontSize_defaultLabel = '20';
    this.config.image_removeLinkByEmptyURL = false;
    this.config.extraPlugins = 'uploadimage';
    this.config.removeButtons = 'Cut,Copy,Paste,Undo,Redo,Anchor,Strike,Subscript,Superscript';
    this.config.fileTools_requestHeaders = {
      //'X-Requested-With': 'XMLHttpRequest',
      //'authorization': `Bearer ` + this.adalService.accessToken
    };
  }


  @Input()
  set passDataInComponent(paramData) {
    if (!paramData) {
      return;
    }
    this.paramData = paramData;
  }

  ngOnInit() {
    this.bannerAccordianHeading = "Add Leaderboard Banner";
    this.campaignLookUpForm = this.formBuilder.group({
      campaignName: ['', [Validators.required, Validators.pattern(/[\S]/), InputValidationService.numbersLettersUnderscoreAndSpace]],
      campaignUtmId: ['', [Validators.required, Validators.pattern(/[\S]/), InputValidationService.numbersLettersAndUnderscore]],
      beginDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      campaignCategoryId: ['', [Validators.required]],
      bannerId: [],
      id: ['']
    });

    this.campaignLookUpForm.value;

    this.bannerForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/[\S]/), InputValidationService.numbersLettersUnderscoreAndSpace]],
      heading: ['', [Validators.required, Validators.pattern(/[\S]/)]],
      content: ['', [Validators.required, Validators.pattern(/[\S]/)]],
      buttonText: ['', [Validators.required, Validators.pattern(/[\S]/)]],
      linkCategory: ['', [Validators.required]], //OnorOffPlatform
      onPlatfromRedirectionLink: [''], //OnPlatfromRedirectionLink
      offPlatfromRedirectionLink: [''], //OffPlatfromRedirectionLink
      enterWebsource: [''] //Enter into LMS websource
    });

    let element = document.getElementById('bannerCheck1-nobanner') as HTMLInputElement;
    if (element) {
      element.checked = true;
      this.bannerCheck1();
    }

    this.minDatesOfCalender();
    this.maxStartDate = null;
  }


  get campaignLookUpFormControls() { return this.campaignLookUpForm.controls; }
  get leaderBannerFormControls() { return this.bannerForm.controls; }

  minDatesOfCalender() {

    const current = new Date();
    this.minStartDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    this.minEndDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

  }



  getActiveCampaigns() {
    this.campaignService.getActiveCampaigns().subscribe((data: any) => {
      this.campaignsList = data;
      this.isShown = true;
    }, (err: HttpErrorResponse) => {

    });
  }


  linkList: any = ['/portal/dashboard', '/portal/account', '/portal/profile', '/portal/setting', '/portal/offers', '/portal/financial-wellness', '/portal/insurance', '/portal/faq'];


  reset() {
    this.editDateForAOngoingCamapign = false;
    this.modifyDatesOfCamapign = null;
    this.isAOngoingCampaign = null;
    this.isBannerUpdated = false;
    this.endDateModel = null;
    this.beginDateModel = null;
    this.endDateValueBeforeToggle = null;
    // let element = document.getElementById('bannerCheck1-nobanner') as HTMLElement;
    // if(element){
    //   element.setAttribute("checked","checked");
    //   element.click();
    // }
  }


  updateBeginDateOfCampaign() {
    var existingBeginDate = this.campaignLookUpForm.get('beginDate');
    existingBeginDate.clearAsyncValidators();
    existingBeginDate.updateValueAndValidity();
    existingBeginDate.setValidators([Validators.required]);
    existingBeginDate.updateValueAndValidity();
  }

  addEndDatevalidation() {
    var endDate = this.campaignLookUpForm.get('endDate');
    endDate.setValidators(Validators.required);
    endDate.updateValueAndValidity();
  }

  removeEndDatevalidation() {

    var endDate = this.campaignLookUpForm.get('endDate');
    endDate.setValidators(null);
    endDate.updateValueAndValidity();
  }


  modificationWithDateRequest() {

    let bgdate = this.campaignLookUpForm.value.beginDate;
    let beginDateString = new Date(bgdate.year, bgdate.month - 1, bgdate.day).toDateString();
    let enddate = this.campaignLookUpForm.value.endDate;
    let endDateString = null;
    if (enddate != null) {
      endDateString = new Date(enddate.year, enddate.month - 1, enddate.day).toDateString();
    }


    const param = {
      campaignName: this.campaignLookUpForm.value.campaignName,
      campaignUtmId: this.campaignLookUpForm.value.campaignUtmId,
      beginDate: beginDateString,
      endDate: endDateString,
      campaignCategoryId: this.campaignLookUpForm.value.campaignCategoryId,
      bannerId: this.campaignLookUpForm.value.bannerId,
      id: this.editId
    }


    this.campaignService.updatedCampaignById(param).subscribe((data: any) => {
      this.isInsertMode = true;
      this.campaignLookUpForm.reset();
      this.campaignLookUpForm.updateValueAndValidity();
      // this.addEndDatevalidation();
      this.toggleNoEndExistsCheckbox(false);
      this.warningMsgCampaignLookup = '';
      this.successMsgCampaignLookup = "Updated Successfully";
      this.modalService.dismissAll();
      this.greyOutCampaignUtmIdToggle();
      this.editId = '';
      this.reset();
      setTimeout(() => {
        this.successMsgCampaignLookup = '';
        this.ngOnInit();
        this.openOverViewTabAndCampaigns();
      }, 2000);

    },
      (err: HttpErrorResponse) => {
        this.warningMsgCampaignLookup = err.error.join('\n');
        this.modalService.dismissAll();
      });

  }

  closeWarningPopup() {
    this.modifyDatesOfCamapign = false;
    this.modalService.dismissAll();
  }


  saveCampaignInfo() {
    if (!this.isInsertMode) {
      if (this.isAOngoingCampaign) {
        this.updateBeginDateOfCampaign();



        if (this.campaignLookUpForm.invalid) {
          this.campaignLookUpForm.markAllAsTouched();
          return;
        }

        this.isBannerUpdated = (this.campaignLookUpForm.get('bannerId').value != this.bannerOnLoad) ? true : false;

        if (this.editDateForAOngoingCamapign || (this.isBannerUpdated && this.isAOngoingCampaign)) {
          this.modalService.open(this.activeWarningCampaignsModal, { size: 'sm' });
          return;
        }
      }
    }


    if (this.campaignLookUpForm.invalid) {
      this.campaignLookUpForm.markAllAsTouched();
      return;
    }

    console.log(this.campaignLookUpForm.value);

    let bgdate = this.campaignLookUpForm.value.beginDate;
    let beginDateString = new Date(bgdate.year, bgdate.month - 1, bgdate.day).toDateString();
    let enddate = this.campaignLookUpForm.value.endDate;
    let endDateString = null;
    if (enddate) {
      endDateString = new Date(enddate.year, enddate.month - 1, enddate.day).toDateString();
    }

    const param = {
      campaignName: this.campaignLookUpForm.value.campaignName,
      campaignUtmId: this.campaignLookUpForm.value.campaignUtmId,
      beginDate: beginDateString,
      endDate: endDateString,
      campaignCategoryId: this.campaignLookUpForm.value.campaignCategoryId,
      bannerId: this.campaignLookUpForm.value.bannerId,
      id: this.editId
    }

    param;

    if (this.isInsertMode) {
      this.campaignService.saveCampaignLookUp(param).subscribe((data: any) => {
        this.warningMsgCampaignLookup = '';
        this.successMsgCampaignLookup = "Added Successfully";
        this.campaignLookUpForm.reset();
        this.isInsertMode = true;
        this.toggleNoEndExistsCheckbox(false);
        this.resetBanners();
        this.editId = '';
        this.reset();
        setTimeout(() => {
          this.successMsgCampaignLookup = '';
          this.ngOnInit();
          this.openOverViewTabAndCampaigns();
        }, 2000);

      },
        (err: HttpErrorResponse) => {
          this.warningMsgCampaignLookup = err.error.join('\n');
        });
    }


    if (!this.isInsertMode) {
      this.campaignService.updatedCampaignById(param).subscribe((data: any) => {
        this.modalService.dismissAll();
        this.isInsertMode = true;
        this.warningMsgCampaignLookup = '';
        this.successMsgCampaignLookup = "Updated Successfully";
        this.campaignLookUpForm.reset();
        this.resetBanners();
        this.toggleNoEndExistsCheckbox(false);
        this.greyOutCampaignUtmIdToggle();
        this.reset();
        setTimeout(() => {
          this.successMsgCampaignLookup = '';

          this.ngOnInit();
          this.openOverViewTabAndCampaigns();
        }, 2000);

      },
        (err: HttpErrorResponse) => {
          this.warningMsgCampaignLookup = err.error.join('\n');
        });
    }

  }


  getCampaigns() {

    this.campaignService.getCampaigns().subscribe((data: any) => {
      this.campaignsList = data;
      this.isShown = true;

      // if(this.expandCampaignTab)
      // {
      //   let ujAccodian = document.getElementById('userJourny') as HTMLInputElement;  
      //   ujAccodian.click();
      // }


    }, (err: HttpErrorResponse) => {

    });
  }


  getActiveLeaderBoardBanner() {

    this.leadeBoardBannerService.getActiveBanners().subscribe((data: any) => {


      // if(this.expandBannerTab)
      // {
      //   let ujAccodian = document.getElementById('bannerJourny') as HTMLElement;  
      //   ujAccodian.click();
      // }

      this.leaderBoardBannerList = data;

    }, (err: HttpErrorResponse) => {

    });

  }


  getCamapignCategory() {
    if (this.campaignCategoryList == '') {
      this.campaignService.getCampaignCategory().subscribe((data: any) => {
        this.campaignCategoryList = data;
      }, (err: HttpErrorResponse) => {

      });
    }

  }


  edituserjourney(i) {
    this.campaignLookUpForm.reset();
    this.campaignLookUpForm.setErrors(null);
    this.warningMsgCampaignLookup = '';
    this.successMsgCampaignLookup = '';
    this.reset();
    this.openUJTab();
    this.isInsertMode = false; //EditJourney
    this.editId = i;
    console.log('id is' + i);
    this.campaignLookUpForm.reset();
    this.addEndDatevalidation();
    this.getLogDetails();
  }

  openUJTab() {
    let element = document.getElementById('userJourney-tab') as HTMLElement;

    if (element != null) {
      element.click();
    }
  }


  openManageBannerTab() {
    let element = document.getElementById('rankingBanners-tab') as HTMLElement;

    if (element != null) {
      element.click();
      let element1 = document.getElementById('addEditLeaderboardBanner') as HTMLElement;
      element1.click();
    }
  }



  openManageTab() {
    let element = document.getElementById('userJourney-tab') as HTMLElement;
    if (element != null) {
      element.click();
      this.UJCTabOpened = true;
      this.createNewUserJourney();
      this.isInsertMode = true;
    }
  }


  openOverViewTabAndCampaigns() {
    let element = document.getElementById('overview-tab') as HTMLElement;
    if (element != null) {
      element.click();
      //this.expandCampaignTab = true;
      this.getCampaigns();

    }
  }



  openOverViewTabAndBanners() {
    let element = document.getElementById('overview-tab') as HTMLElement;
    if (element != null) {
      element.click();
      // this.expandBannerTab = true;
      this.getActiveLeaderBoardBanner();

    }
  }



  greyOutCampaignUtmIdToggle() {
    let element = document.getElementById('campaignUtmId') as HTMLInputElement;
    if (element && this.isInsertMode) {
      element.removeAttribute("disabled");
    }
    else {
      element.setAttribute("disabled", "true");
    }
  }

  getLogDetails() {
    const param = { campaignId: this.editId };

    this.campaignService.getCampaignLookUpById(param).subscribe((data: any) => {
      let startDate = NgbDate.from(null);
      let endDate = NgbDate.from(null);
      this.endDateAsReceived = data.endDate;
      this.isInsertMode = false;
      this.bannerOnLoad = data.bannerId

      this.minDatesOfCalender();

      let diff = moment(new Date()).diff(moment(data.beginDate), 'days');

      if (data.endDate) {
        this.endDateStr = moment(data.endDate).format('YYYY-MM-DD').split("-");
        endDate = new NgbDate(Number(this.endDateStr[0]), Number(this.endDateStr[1]), Number(this.endDateStr[2]));
        this.endDateOnLoad = moment(data.endDate).format('YYYY-MM-DD');
        this.nextBeginDate = moment(data.endDate).subtract(1, 'days').format('YYYY-MM-DD').split("-");
        this.maxStartDate = {
          year: Number(this.nextBeginDate[0]),
          month: Number(this.nextBeginDate[1]),
          day: Number(this.nextBeginDate[2])
        };

      } else {
        this.maxStartDate = null;
      }


      if (data.beginDate) {
        this.startdateStr = moment(data.beginDate).format('YYYY-MM-DD').split("-");
        startDate = new NgbDate(Number(this.startdateStr[0]), Number(this.startdateStr[1]), Number(this.startdateStr[2]));
        this.beginDateOnLoad = moment(data.beginDate).format('YYYY-MM-DD');

        this.nextEndDate = moment(data.beginDate).add(1, 'days').format('YYYY-MM-DD').split("-");
        if (diff <= 0) {
          this.minEndDate = {
            year: Number(this.nextEndDate[0]),
            month: Number(this.nextEndDate[1]),
            day: Number(this.nextEndDate[2])
          }
        };

      } else {
        this.minEndDate = null;
        this.maxStartDate = null;
      }

      if (diff >= 0) {
        this.isAOngoingCampaign = true;

      } else {
        this.isAOngoingCampaign = false;
      }

      if (data.bannerId != null) {
        this.bannerCheck2();
        this.campaignIsLinkedToABanner = true;
        this.bannerIdLinkedToCampaign = data.bannerId;
        let element = document.getElementById('bannerCheck-pre-existingBanner') as HTMLElement;

        if (element) {
          element.click();

        }
      }

      if (data.bannerId == null) {
        this.bannerCheck2();
        this.campaignIsLinkedToABanner = false;
        let element = document.getElementById('bannerCheck1-nobanner') as HTMLElement;

        if (element) {
          element.click();

        }
      }


      this.campaignLookUpForm.patchValue({
        beginDate: startDate,
        campaignName: data.campaignName,
        endDate: endDate,
        campaignUtmId: data.campaignUtmId,
        campaignCategoryId: data.campaignCategoryId,
        bannerId: data.bannerId,
        id: this.editId
      });



      this.checkNoEndDateCheckbox();
    }, (err: HttpErrorResponse) => {

    });


    this.greyOutCampaignUtmIdToggle();
  }




  onEndDateSelect(evt: any) {
    this.campaignLookUpForm.patchValue({ endDate: new Date(evt.year, evt.month - 1, evt.day) });
    this.endDateModel = evt;

    if (this.isAOngoingCampaign) {

      let endDateDiff = moment(this.endDateOnLoad).diff(moment(new Date(evt.year, evt.month - 1, evt.day), 'YYYY-MM-DD').format(), 'days');
      if (endDateDiff != 0) {
        this.editDateForAOngoingCamapign = true;
      }

    }
    this.maxStartDate = {
      year: evt.year,
      month: evt.month,
      day: evt.day - 1
    };
  }

  onBeginDateSelect(evt: any) {
    this.campaignLookUpForm.patchValue({ beginDate: new NgbDate(evt.year, evt.month - 1, evt.day) });
    this.campaignLookUpForm.updateValueAndValidity();
    this.beginDateModel = evt;


    var nextDate = moment({
      year: evt.year,
      month: evt.month - 1,
      day: evt.day
    }).add(1, 'days').format('YYYY-MM-DD').split("-");


    this.minEndDate = {
      year: Number(nextDate[0]),
      month: Number(nextDate[1]),
      day: Number(nextDate[2])
    }




    if (this.isAOngoingCampaign) {
      let beginDateDiff = moment(this.beginDateOnLoad).diff(moment(new Date(evt.year, evt.month - 1, evt.day), 'YYYY-MM-DD')
        .format(), 'days');
      if (beginDateDiff != 0) {
        this.editDateForAOngoingCamapign = true;
      }
    }
  }


  bannerCheck1() {
    document.getElementById('bann1').style.display = 'none';
    document.getElementById('newlyAddedBanner').style.display = 'none';

    this.campaignLookUpForm.controls['bannerId'].clearValidators();
    var bannerId = this.campaignLookUpForm.get('bannerId');

    if (bannerId.value != null) {
      bannerId.setValue('');
    }

    bannerId.updateValueAndValidity();
  }

  bannerCheck2() {
    document.getElementById('bann1').style.display = 'block';
    document.getElementById('newlyAddedBanner').style.display = 'none';
    var bannerId = this.campaignLookUpForm.get('bannerId');
    if (bannerId) {
      bannerId.setValidators(Validators.required);
      //this.isBannerUpdated = true;
    }

    bannerId.updateValueAndValidity();


  }
  bannerCheck3() {
    var bannerId = this.campaignLookUpForm.get('bannerId');
    this.isInsertModelBanner = true;

    if (bannerId) {
      bannerId.setValue('');
      bannerId.setValidators(Validators.required);
    }
    bannerId.updateValueAndValidity();
    document.getElementById('bann1').style.display = 'none';
    this.clearBanner();
    this.modalService.open(this.newBannerModal, { size: 'lg' });
  }

  clearBanner() {
    this.bannerForm.reset();
    this.ckeditor.instance.setData('<p></p>');
    this.ckeditor.instance.updateElement();
    this.bannerForm.setErrors(null);
    this.warningMsgLeaderBoardBanner = '';
    this.successMsgLeaderBoardBanner = '';
    this.resetLinkCategory();
  }

  buttonActionSelect() {
    this.buttonActionSelectHolder = true;
    this.buttonActionLinkHolder = false;
    this.buttonActionLmsHolder = false;
  }
  buttonActionLink() {
    this.buttonActionSelectHolder = false;
    this.buttonActionLinkHolder = true;
    this.buttonActionLmsHolder = false;
  }
  buttonActionLms() {
    this.buttonActionSelectHolder = false;
    this.buttonActionLinkHolder = false;
    this.buttonActionLmsHolder = true;
  }

  resetLinkCategory() {
    this.buttonActionSelectHolder = false;
    this.buttonActionLinkHolder = false;
    this.buttonActionLmsHolder = false;
  }

  deletePop(startDate, id) {

    let diff = moment(new Date()).diff(moment(startDate), 'days');
    this.softDeleteId = id;
    if (diff >= 0) { //Its a ongoingCamapigin 
      this.showdeleteWarningMessage = true;
    }
    else {
      //Its a upcomingCamapigin 
      this.showdeleteWarningMessage = false;
    }

    this.showDeleteWaringMessage = true;
    this.modalService.open(this.deleteModal, { size: 'sm' });
  }

  previewPopup() {
    this.modalService.open(this.previewModel, { size: 'xl' });
  }

  softDeleteCampaign(softDeleteCampaign) {
    const param = {
      campaignId: softDeleteCampaign,
    }

    this.campaignService.softDeleteCampaign(param).subscribe((data: any) => {
      this.showDeleteWaringMessage = false;
      this.successMsgDeletePopUp = 'Deleted Successfully';
      this.showdeleteWarningMessage = false;
      setTimeout(() => {
        this.successMsgDeletePopUp = '';
        this.getCampaigns();
        this.modalService.dismissAll();
      }, 2000);
    },
      (err: HttpErrorResponse) => {
        this.warningMsgDeletePopUp = "There was some error."

        setTimeout(() => {

          this.modalService.dismissAll();
          this.warningMsgDeletePopUp = '';
        }, 1000);
      });
  }
  activeWarningBannerPops() {
    this.modalService.open(this.activeWarningBannerModal, { size: 'sm' });
  }
  activeWarningCampaignsPops() {
    this.modalService.open(this.activeWarningCampaignsModal, { size: 'sm' });
  }


  createNewUserJourney() {
    this.isAOngoingCampaign = false;
    this.isInsertMode = true;
    this.campaignLookUpForm.reset();
    this.campaignLookUpForm.setErrors(null);
    if (!this.UJCTabOpened) {
      this.openUJTab();
    }
    this.checkNoEndDateCheckbox();
    this.successMsgCampaignLookup = '';
    this.warningMsgCampaignLookup = '';
    this.reset();
    this.greyOutCampaignUtmIdToggle();
    this.minEndDate = null;
    this.maxStartDate = null;

  }

  checkNoEndDateCheckbox() {
    if (this.isInsertMode) {
      let noEndDatetoggle = document.getElementById('noEndExists') as HTMLInputElement;
      if (noEndDatetoggle) {
        noEndDatetoggle.checked = true;
      }

      this.addEndDatevalidation();
      this.toggleNoEndExistsCheckbox(false);
      this.removeDisableAttributeForEndDate();
    }
    else {
      if (this.endDateAsReceived == null) {

        this.disableEditDateForEndDateToggle();
        this.toggleNoEndExistsCheckbox(true);
        this.removeEndDatevalidation();
      }
      else {
        this.toggleNoEndExistsCheckbox(false);
        this.removeDisableAttributeForEndDate();
      }

    }
  }

  resetEndDateOfCampaign() {

    let element = document.getElementById('noEndExists') as HTMLInputElement;
    let endDatetoggle = document.getElementById('endDate-toggle') as HTMLInputElement;
    let endDate = this.campaignLookUpForm.get("endDate");


    if (endDate) {
      if (endDate.value != null) {
        this.endDateValueBeforeToggle = endDate.value;
        endDate.setValue(NgbDate.from(null));
        this.maxStartDate = null;

      }

    }

    if (element) {
      if (element.checked) {
        if (endDatetoggle) {
          endDatetoggle.disabled = true;
        }

        if (!this.isInsertMode) {
          this.editDateForAOngoingCamapign = true;
        }
        endDate.clearValidators();
        endDate.setErrors(null);

      } else {
        if (endDatetoggle) {
          endDatetoggle.disabled = false;
        }
        endDate.setValidators(Validators.required);
      }
      endDate.updateValueAndValidity();

    }

  }


  enableEndDateToggle() {
    let endDate = document.getElementById('endDate-toggle') as HTMLInputElement;
    if (endDate) {
      endDate.setAttribute("disabled", "disabled");
    }
  }

  removeDisableAttributeForEndDate() {
    let endDate = document.getElementById('endDate-toggle') as HTMLInputElement;
    if (endDate) {
      endDate.removeAttribute("disabled");
    }
  }

  removeDisableAttributeFoBeginDate() {
    let beginDate = document.getElementById('beginDate-toggle') as HTMLInputElement;
    if (beginDate) {
      beginDate.removeAttribute("disabled");
    }
  }

  disablednoEndExists() {
    let noEndExists = document.getElementById('noEndExists') as HTMLInputElement;
    if (noEndExists) {
      noEndExists.setAttribute("disabled", "disabled");
    }

  }

  toggleNoEndExistsCheckbox(setAttr) {
    let noEndExists = document.getElementById('noEndExists') as HTMLInputElement;
    if (noEndExists) {
      if (setAttr) {
        noEndExists.checked = true;
      }
      else {
        noEndExists.checked = false;
      }
    }
  }


  disableEditDateForEndDateToggle() {
    let endDatetoggle = document.getElementById('endDate-toggle') as HTMLInputElement;
    if (endDatetoggle) {
      endDatetoggle.disabled = true;
    }

  }



  savebanner(isFromPopup: boolean) {
    this.bannerForm.value;
    if (this.bannerForm.invalid) {
      this.bannerForm.markAllAsTouched();
      return;
    }

    let route = "";
    if (this.bannerForm.value.linkCategory == 'onPlatformNavigate') {
      route = this.bannerForm.value.onPlatfromRedirectionLink;
    }
    else if (this.bannerForm.value.linkCategory == 'enterIntoLms') {
      route = this.bannerForm.value.enterWebsource;
      if (route.trim() == '') {
        this.bannerForm;
        return;
      }
    }
    else if (this.bannerForm.value.linkCategory == 'offPlatformNavigate') {
      route = this.bannerForm.value.offPlatfromRedirectionLink;
    }


    const param = {
      name: this.bannerForm.value.name,
      heading: this.bannerForm.value.heading,
      content: this.bannerForm.value.content,
      buttonText: this.bannerForm.value.buttonText,
      linkCategory: this.bannerForm.value.linkCategory, //OnOffPlatfor
      routeToLink: route,
      isActive: true,
      id: this.bannerId
    }


    if (this.isInsertModelBanner) {
      this.leadeBoardBannerService.saveBannerInfo(param).subscribe((data: any) => {
        this.ckeditor.instance.setData('<p></p>');
        this.ckeditor.instance.updateElement();
        this.bannerForm.reset();
        this.bannerContent = '';
        this.successMsgLeaderBoardBanner = "Added Successfully";
        this.warningCampaignBannerBindingMsg = '';

        setTimeout(() => {
          this.successMsgLeaderBoardBanner = '';
          this.isInsertModelBanner = true;
          this.removeValidationForOnPlatformRoute();
          this.removeValidationForOffPlatformRoute();
          this.removeValidationForEnterIntoLms();
          if (isFromPopup) {
            this.getActiveLeaderBoardBanner();
            document.getElementById('newlyAddedBanner').style.display = 'block';

            this.modalService.dismissAll();
            this.campaignLookUpForm.patchValue({
              bannerId: data.id
            });

          }
          else {
            this.openOverViewTabAndBanners();
            this.ngOnInit();
          }
          //
        }, 2000);
      },
        (err: HttpErrorResponse) => {
          err.error;
          this.warningMsgLeaderBoardBanner = err.error;

        });
    }
    else {
      this.leadeBoardBannerService.updateBannerInfo(param).subscribe((data: any) => {
        this.ckeditor.instance.setData('<p></p>');
        this.ckeditor.instance.updateElement();
        this.removeValidationForOnPlatformRoute();
        this.removeValidationForOffPlatformRoute();
        this.removeValidationForEnterIntoLms();
        this.bannerForm.reset();
        this.warningMsgLeaderBoardBanner = '';
        this.successMsgLeaderBoardBanner = "Updated Successfully";
        this.isInsertModelBanner = true;
        setTimeout(() => {
          this.successMsgLeaderBoardBanner = '';
          this.ngOnInit();
          this.openOverViewTabAndBanners();
        }, 5000);
      },
        (err: HttpErrorResponse) => {
          err.error;

          this.warningMsgLeaderBoardBanner = err.error;

        });
    }

  }

  handleChange(evt: any) {
    var defaultValue = evt.currentTarget.defaultValue;
    var onPlatformRedirction = this.bannerForm.get('onPlatfromRedirectionLink');
    var offPlatformRedirction = this.bannerForm.get('offPlatfromRedirectionLink');
    var enterIntoLms = this.bannerForm.get('enterWebsource');
    if (defaultValue == 'onPlatformNavigate') {
      this.buttonActionSelect();
      onPlatformRedirction.setValidators(Validators.required);
      offPlatformRedirction.setValidators(null);
      enterIntoLms.setValidators(null);
    }

    if (defaultValue == 'offPlatformNavigate') {
      this.buttonActionLink();
      offPlatformRedirction.setValidators([Validators.required, InputValidationService.isValidURL]);
      onPlatformRedirction.setValidators(null);
      enterIntoLms.setValidators(null);
    }
    if (defaultValue == 'enterIntoLms') {
      this.buttonActionLms();
      enterIntoLms.setValidators([Validators.required, Validators.pattern(/[\S]/)]);
      onPlatformRedirction.setValidators(null);
      offPlatformRedirction.setValidators(null);
    }
    offPlatformRedirction.updateValueAndValidity();
    onPlatformRedirction.updateValueAndValidity();
    enterIntoLms.updateValueAndValidity();
  }


  editbannerjourney(i) {
    this.bannerForm.reset();
    this.bannerForm.setErrors(null);
    this.warningMsgLeaderBoardBanner = '';
    this.successMsgLeaderBoardBanner = '';
    this.reset();
    this.openManageBannerTab();
    this.isInsertModelBanner = false; //EditJourney
    this.bannerId = i;
    console.log('id is' + i);
    this.getBannerLogDetails();
  }


  getBannerLogDetails() {
    const param = { id: this.bannerId };
    this.bannerAccordianHeading = "Edit Leaderboard Banner";
    this.leadeBoardBannerService.getBannerById(param).subscribe((data: any) => {

      let onPlatformUrl = '', offPlatformUrl = '', lmsWebsource = '';
      if (data.linkCategory == 'onPlatformNavigate') {
        onPlatformUrl = data.routeToLink;
        this.buttonActionSelect();
        this.addValidationForOnPlatformRoute();
      }

      if (data.linkCategory == 'offPlatformNavigate') {
        offPlatformUrl = data.routeToLink;
        this.buttonActionLink();
        this.addValidationForOffPlatformRoute();
      }
      if (data.linkCategory == 'enterIntoLms') {
        lmsWebsource = data.routeToLink;
        this.buttonActionLms();
        this.addValidationForEnterIntoLms();
      }


      console.log(data);
      this.bannerForm.patchValue({
        name: data.name,
        rank: data.rank,
        heading: data.heading,
        content: data.content,
        buttonText: data.buttonText,
        linkCategory: data.linkCategory,
        offPlatfromRedirectionLink: offPlatformUrl,
        onPlatfromRedirectionLink: onPlatformUrl,
        enterWebsource: lmsWebsource
      });


      this.removeValidationForOffPlatformRoute();
      this.removeValidationForOnPlatformRoute();
      this.removeValidationForEnterIntoLms();
    }, (err: HttpErrorResponse) => {

    });
  }


  addValidationForOnPlatformRoute() {
    var onPlatformRedirction = this.bannerForm.get('onPlatfromRedirectionLink');
    onPlatformRedirction.setValidators(Validators.required);
    onPlatformRedirction.updateValueAndValidity();
  }


  addValidationForOffPlatformRoute() {
    var offPlatformRedirction = this.bannerForm.get('offPlatfromRedirectionLink');
    offPlatformRedirction.setValidators([Validators.required, InputValidationService.isValidURL]);
    offPlatformRedirction.updateValueAndValidity();
  }
  addValidationForEnterIntoLms() {
    var enterIntoLms = this.bannerForm.get('enterWebsource');
    enterIntoLms.setValidators([Validators.required, Validators.pattern(/[\S]/)]);
    enterIntoLms.updateValueAndValidity();
  }

  removeValidationForOnPlatformRoute() {
    var onPlatformRedirction = this.bannerForm.get('onPlatfromRedirectionLink');
    onPlatformRedirction.setValidators(null);
    onPlatformRedirction.updateValueAndValidity();
  }


  removeValidationForOffPlatformRoute() {
    var offPlatformRedirction = this.bannerForm.get('offPlatfromRedirectionLink');
    offPlatformRedirction.setValidators(null);
    offPlatformRedirction.updateValueAndValidity();
  }
  removeValidationForEnterIntoLms() {
    var enterIntoLms = this.bannerForm.get('enterWebsource');
    enterIntoLms.setValidators(null);
    enterIntoLms.updateValueAndValidity();
  }
  resetBanners() {
    let element = document.getElementById('bannerCheck1-nobanner') as HTMLInputElement;
    if (element) {
      element.checked = true;
      this.bannerCheck1();
    }
  }

  closeAddNewBanner() {
    if (!this.campaignIsLinkedToABanner) {
      this.resetBanners();
    }


    if (this.campaignIsLinkedToABanner) {
      this.bannerCheck2();
      let element = document.getElementById('bannerCheck-pre-existingBanner') as HTMLElement;
      element.click();
      this.campaignLookUpForm.patchValue({
        bannerId: this.bannerIdLinkedToCampaign
      })
    }
  }


  createNewLeaderboardBanner() {

    this.clearBanner();
    this.ckeditor.instance.setData('<p></p>');
    this.ckeditor.instance.updateElement();
    this.isInsertModelBanner = true;
    this.bannerAccordianHeading = "Add Leaderboard Banner";
    this.openManageBannerTab();
    this.removeValidationForOffPlatformRoute();
    this.removeValidationForOnPlatformRoute();
    this.removeValidationForEnterIntoLms();
    this.warningMsgLeaderBoardBanner = '';
    this.successMsgLeaderBoardBanner = '';
  }


  getInactiveAndEndedCampaings() {
    this.campaignService.getInactiveAndEndedCampaings().subscribe((data: any) => {
      this.recycleBinCampaignList = data;
    },
      (err: HttpErrorResponse) => {

      });
  }


  getInactiveLeaderboardbanner() {
    this.leadeBoardBannerService.getInActivedBanners().subscribe((data: any) => {
      this.recycleLeaderBoardBannerList = data;

    },
      (err: HttpErrorResponse) => {

      });
  }

  deleteBannerPopup(i) {
    this.bannerId = i;
    this.modalService.open(this.deleteModalBanner, { size: 'md' });
    this.clearMessage();
  }

  softDeleteLeaderboardCampaign() {
    const param = {
      id: this.bannerId,
    }

    this.leadeBoardBannerService.softDeleteLeaderBoardBaner(param).subscribe((data: any) => {

      this.successMsgLeaderBoardBannerDelete = 'Deleted Successfully';
      setTimeout(() => {
        this.successMsgLeaderBoardBannerDelete = '';
        this.getActiveLeaderBoardBanner();
        this.modalService.dismissAll();
      }, 2000);
    },
      (err: HttpErrorResponse) => {
        this.warningMsgLeaderBoardBannerDelete = "There was some error."

        setTimeout(() => {

          this.modalService.dismissAll();
          this.warningMsgLeaderBoardBannerDelete = '';
        }, 1000);
      });
  }


  previewPopupBanner(i) {
    const param = { id: i };
    this.leadeBoardBannerService.getBannerById(param).subscribe((data: any) => {

      this.bannerHeadingOverView = data.heading;
      this.bannerContentOverView = data.content;
      this.bannerButtonDisplayNameOverView = data.buttonText;

      this.modalService.open(this.previewModelOverview, { size: 'xl' });

    }, (err: HttpErrorResponse) => {

    });


  }

  clearBannerDataOverview() {
    this.bannerHeadingOverView = '';
    this.bannerContentOverView = '';
    this.bannerButtonDisplayNameOverView = '';
  }



  isBannerLinkedToACampaign() {

    const param = {
      id: this.bannerId,
    }

    this.campaignService.isBannerLinkedToAAtiveCamapaign(param).subscribe((data: any) => {

      if (data.isLinkedToAOngoingCampaign) {
        this.bannerLinkedToACamapaign = true;
        this.linkedCampaign = data.listOfLinkedCampaign;
      }
      else {
        this.softDeleteLeaderboardCampaign();
      }
    }, (err: HttpErrorResponse) => {

    });

  }


  clearMessage() {
    this.linkedCampaign = '';
    this.bannerLinkedToACamapaign = null;
  }

}
