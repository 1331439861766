import { Pipe, PipeTransform } from '@angular/core';
import { CommunicationLogReasonType, CommunicationLogReasonTypeMapping } from '../enums/communication-log-reason-type';

@Pipe({
  name: 'parseCommunicationLogReasonType'
})
export class ParseCommunicationLogReasonTypePipe implements PipeTransform {

  transform(value: any): any {
    const parsedData = CommunicationLogReasonTypeMapping.filter((data) => {
      return data.value === value;
    });
    if (parsedData.length > 0) {
      return parsedData[0].type;
    }
    return '';
  }
}
