import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WelcomeTextService {
 
  constructor(private http: HttpClient) { }
 
  insertWelcomeText(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcWelcomeText/SaveWelcomeText', data);
  }

  getLatestWelcomeMessage() {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcWelcomeText/GetLatestWelcomeMessage');
  }

  getFilteredMessage(params: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcWelcomeText/GetWelcomeTextListByStatus', params);
  }

  getWelcomeMessageById(params: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcWelcomeText/GetWelcomeTextById', params);
  }

  updatedWelcomeTextId(params: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/CcWelcomeText/UpdatedWelcomeTextId', params); 
  }

}
