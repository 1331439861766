import { Component, OnInit, Input } from '@angular/core';
import { CommunicationService } from '../../RxJs/communication.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-communication-preference',
  templateUrl: './communication-preference.component.html',
  styleUrls: ['./communication-preference.component.css']
})
export class CommunicationPreferenceComponent implements OnInit {

  communicationPreferenceLogData;
  constructor(private communicationService: CommunicationService) { }

  ngOnInit() {
  }

  @Input()
  set passDataInComponent(paramData) {
    this.communicationPreferenceLogData = null;
    
    if (!paramData) {
      return;
    }
    this.getCommunicationPreferenceLogs(paramData);
  }

  getCommunicationPreferenceLogs(paramData) {
    const param = { azureAdObjectId: paramData.azureAdObjectId, idNumber: paramData.idNumber };
    this.communicationService.getAgentCommunicationPreferences(param).subscribe((data: any) => {
      this.communicationPreferenceLogData = data;
     
    },
      (err: HttpErrorResponse) => {
      });
  }

}
