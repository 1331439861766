import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AccountService {

  constructor(private http: HttpClient) { }

  accountSummary(params: any) {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcAccount/AccountSummary', { params });
  }

  otherAccounts(params: any) {
    return this.http.get(environment.ccAgentWebApiUrl + 'api/CcAccount/OtherAccounts', { params });
  }

}
