import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/RxJs/user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-registration-log',
  templateUrl: './registration-log.component.html',
  styleUrls: ['./registration-log.component.css']
})
export class RegistrationLogComponent implements OnInit {

  registrationLogsData;
  externalLeadData;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  @Input()
  set passDataInComponent(paramData) {
    this.registrationLogsData = null;
    this.externalLeadData=null;
    if (!paramData) {
      return;
    }
    this.getRegistrationLogs(paramData);
  }

  getRegistrationLogs(paramData) {
    const param = { azureAdObjectId: paramData.azureAdObjectId, userId: paramData.userId };
    this.userService.getRegistrationLogs(param).subscribe((data: any) => {
      this.registrationLogsData = data.registrationLogResponse;
      this.externalLeadData = data.externalLead;
    },
      (err: HttpErrorResponse) => {
      });
  }
}

